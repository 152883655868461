import React, { useEffect } from "react";
import { Controller, setValue } from "react-hook-form";
import { SelectCustom } from "../../components/ui/SelectCustom";

export const PersonalDetailsForm = ({
  register,
  control,
  errors,
  index = null,
  learner,
  unregister,
}) => {
  const genderOptions = [
    {
      value: "male",
      label: "Mr",
    },
    {
      value: "female",
      label: "Ms",
    },
  ];

  useEffect(() => {
    return () => {
      unregister(`title${index ? "-" + index : ""}`);
      unregister(`firstName${index ? "-" + index : ""}`);
      unregister(`lastName${index ? "-" + index : ""}`);
      unregister(`emailAddress${index ? "-" + index : ""}`);
      unregister(`phone${index ? "-" + index : ""}`);
    };
  }, [learner]);

  return (
    <div className="row gutters-20 mb-30">
      <div className="col-12">
        <div className="d-flex">
          <div className="flex-80 mr-10">
            <div className="form-group">
              <label className="form-group-title">
                Title
                <span className="color-danger ms-1">*</span>
              </label>
              <Controller
                name={`title${index ? "-" + index : ""}`}
                control={control}
                render={({ field: { onChange, onBlur, ref } }) => (
                  <SelectCustom
                    options={genderOptions}
                    onBlur={onBlur}
                    onSelect={(selectedOption) => {
                      onChange(selectedOption);
                    }}
                    ref={ref}
                  />
                )}
                defaultValue={
                  learner?.title ? learner?.title : genderOptions[0].value
                }
              />
            </div>
          </div>
          <div className="flex-auto">
            <div className="form-group">
              <label className="form-group-title">
                First Name
                <span className="color-danger ms-1">*</span>
              </label>
              <input
                className={`input ${
                  errors?.firstName || errors?.[`firstName-${index}`]
                    ? "error"
                    : ""
                }`}
                type="text"
                {...register(`firstName${index ? "-" + index : ""}`, {
                  required: true,
                  validate: (value) => {
                    return !!value.trim();
                  },
                })}
                defaultValue={learner?.firstName && learner.firstName}
                placeholder="John"
              />
              {errors.firstName && (
                <span className="color-danger">
                  {errors.firstName.message || "Enter First name please"}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="form-group">
          <label className="form-group-title">
            Last Name
            <span className="color-danger ms-1">*</span>
          </label>
          <input
            className={`input ${
              errors?.lastName || errors?.[`lastName-${index}`] ? "error" : ""
            }`}
            type="text"
            required=""
            {...register(`lastName${index ? "-" + index : ""}`, {
              required: true,
              validate: (value) => {
                return !!value.trim();
              },
            })}
            placeholder="Doe"
            defaultValue={learner?.lastName && learner.lastName}
          />
          {errors.lastName && (
            <span className="color-danger">
              {errors.lastName.message || "Enter Last Name please"}
            </span>
          )}
        </div>
      </div>
      <div className="col-12">
        <div className="form-group">
          <label className="form-group-title">
            Email<span className="color-danger ms-1">*</span>
          </label>
          <input
            className={`input ${
              errors?.emailAddress || errors?.[`emailAddress-${index}`]
                ? "error"
                : ""
            }`}
            type="text"
            {...register(`emailAddress${index ? "-" + index : ""}`, {
              required: true,
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Enter valid email please",
              },
            })}
            placeholder="john.doe@example.com"
            defaultValue={learner?.emailAddress && learner.emailAddress}
          />
          {(errors?.emailAddress || errors?.[`emailAddress-${index}`])?.message
            ?.length ? (
            <span className="color-danger mb-m-24">
              {errors?.emailAddress?.message ||
                errors?.[`emailAddress-${index}`].message}
            </span>
          ) : null}
        </div>
      </div>
      <div className="col-12">
        <div className="form-group">
          <label className="form-group-title">
            Phone Number
            <span className="color-danger ms-1">*</span>
          </label>
          <input
            className={`input ${
              errors?.phone || errors?.[`phone-${index}`] ? "error" : ""
            }`}
            type="text"
            {...register(`phone${index ? "-" + index : ""}`, {
              required: true,
              pattern: {
                value:
                  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                message: "Enter valid phone number please",
              },
            })}
            placeholder="077XX XXX XXX"
            defaultValue={learner?.phone && learner.phone}
          />
          {(errors?.phone || errors?.[`phone-${index}`])?.message?.length ? (
            <span className="color-danger mb-m-24">
              {errors?.phone?.message || errors?.[`phone-${index}`].message}
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};
