import React from "react";
import { useSelector } from "react-redux";
import { formatPrice } from "../../helpers/index";

export const PersonalDetailsBox = ({ learner, index = 0 }) => {
  const selectedCourse = useSelector((state) => state.courses.selectedCourse);
  const learners = useSelector((state) => state.courses.learners);
  console.log("learner:", learner);

  return (
    <>
      <div className="review-detail-item mb-20">
        <span className="review-detail-item__name">Name:</span>
        <span className="review-detail-item__deliver"></span>
        <span className="review-detail-item__value">
          {learner?.title === "male" ? "Mr. " : "Ms. "} {learner?.firstName}{" "}
          {learner?.lastName}
        </span>
      </div>
      <div className="review-detail-item mb-20">
        <span className="review-detail-item__name">Phone Number:</span>
        <span className="review-detail-item__deliver"></span>
        <span className="review-detail-item__value">{learner?.phone}</span>
      </div>
      {learners.length > 1 && index === 0 ? (
        <>
          <div className="review-detail-item mb-20">
            <span className="review-detail-item__name">Learners:</span>
            <span className="review-detail-item__deliver"></span>
            <span className="review-detail-item__value">
              {learners?.length - 1}
            </span>
          </div>
          <div className="review-detail-item mb-20">
            <span className="review-detail-item__name">
              Total Price (Inc VAT):
            </span>
            <span className="review-detail-item__deliver"></span>
            <span className="review-detail-item__value">
              £{" "}
              {formatPrice(
                (selectedCourse.accept_deposit === 1
                  ? +selectedCourse.price_deposite.toFixed(2)
                  : +selectedCourse.price_online.toFixed(2)) *
                  (learners.length > 1 ? learners.length - 1 : learners.length),
              )}
            </span>
          </div>
        </>
      ) : (
        <div className="review-detail-item mb-20">
          <span className="review-detail-item__name">Price (Inc VAT):</span>
          <span className="review-detail-item__deliver"></span>
          <span className="review-detail-item__value">
            £{" "}
            {formatPrice(
              selectedCourse.accept_deposit === 1
                ? +selectedCourse.price_deposite
                : +selectedCourse.price_online,
            )}
          </span>
        </div>
      )}
      <div className="review-detail-item mb-20">
        <span className="review-detail-item__name">Email:</span>
        <span className="review-detail-item__deliver"></span>
        <span className="review-detail-item__value">
          {learner?.emailAddress}
        </span>
      </div>
    </>
  );
};
