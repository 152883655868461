import React from "react";
import { Accordion } from "../../../components/ui/Accordion";
import { FAQs } from "../../../data/data";

export const FAQ = () => {
  return (
    <div className="fag">
      <h3 className="mb-20 mb-lg-30 heading-3 leading-24">
        Frequently Asked Questions
      </h3>
      {FAQs?.map((item) => (
        <Accordion title={item?.question} key={item?.id}>
          <div dangerouslySetInnerHTML={{ __html: item?.answer }}></div>
        </Accordion>
      ))}
    </div>
  );
};
