import React, { useEffect, useState } from "react";
import { Guarantees } from "../../../components/Guarantees";
import { formatPrice } from "../../../helpers/index";
import { useSearchParams } from "react-router-dom";
import bgImage from "../../../assets/img/img-09-10-23/bg-decore-top-center.svg";
import { useSelector } from "react-redux";
import { Accordion } from "../../../components/ui/Accordion";

export const OrderDetails = ({ course }) => {
  const selectedCourse = useSelector((state) => state.courses.selectedCourse);
  // console.log('selectedCourse:', selectedCourse);

  const learners = useSelector((state) => state.courses.learners);
  const [seconds, setSeconds] = useState(15 * 60);

  const [params] = useSearchParams();
  const step = Number(params.get("step"));
  console.log("step:", step);

  const checkPlusZero = (value) => {
    return String(value)?.length < 2 ? `0${value}` : value;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // Decrease the timer by 1 second
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="col-lg-6 order-first order-lg-last">
      <div className="order-info">
        <div className="order-info__head">
          <span>Your seat has been reserved for:</span>
          <span>
            {checkPlusZero((seconds / 60 - 1).toFixed()) +
              ":" +
              checkPlusZero((seconds % 60).toFixed())}
          </span>
        </div>
        <div className="order-info__body ">
          <div className="order-info__body-head">
            <h3 className="mb-16">Your order</h3>
            {learners.length > 0 && (
              <h3 className="mb-16">
                £
                {formatPrice(
                  (selectedCourse.accept_deposit === 1
                    ? +selectedCourse.price_deposite.toFixed(2)
                    : +selectedCourse.price_online.toFixed(2)) *
                    (learners.length > 1
                      ? learners.length - 1
                      : learners.length),
                )}
              </h3>
            )}
          </div>
          <div
            className="card card-primary card-dash bg-styles-top-center"
            style={{
              backgroundImage: `url(${bgImage})`,
            }}
          >
            <div className="card-body ">
              {step === 4 ? (
                <Accordion
                  headClasses={"spoller__head--style-2 mb-0"}
                  title={
                    <>
                      <h6 className="fs-18 mb-4px">{course?.center}</h6>
                      {/* (6 Hours) */}
                      <p className="mb-16" style={{ fontWeight: "400" }}>
                        {selectedCourse?.duration
                          ? `(${selectedCourse?.duration}) `
                          : null}
                        ({course?.pcode.toUpperCase()})
                      </p>
                      <p>{`Number of learners - ${
                        learners.length > 1
                          ? learners.length - 1
                          : learners.length
                      }`}</p>
                    </>
                  }
                  type="secondary"
                >
                  <>
                    <p
                      className="fw-600 mb-16 lh-22"
                      style={{ marginTop: "16px" }}
                    >
                      Start date - {course?.formattedStartDate}
                    </p>
                    <div className="mb-16 lh-22">
                      <div
                        className="order-details-description"
                        dangerouslySetInnerHTML={{
                          __html: course?.description,
                        }}
                      ></div>
                    </div>
                  </>
                </Accordion>
              ) : (
                <>
                  <h6 className="fs-18 mb-4px">{course?.center}</h6>
                  {/* (6 Hours)  */}
                  <p className="mb-16">
                    {selectedCourse?.duration
                      ? `(${selectedCourse?.duration}) `
                      : null}
                    ({course?.pcode.toUpperCase()})
                  </p>
                  <p className="fw-600 mb-16 lh-22">
                    Start date - {course?.formattedStartDate}
                  </p>
                  <div className="mb-16 lh-22">
                    <div
                      className="order-details-description"
                      dangerouslySetInnerHTML={{ __html: course?.description }}
                    ></div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="d-none d-lg-block">
        <Guarantees row={false} />
      </div>
    </div>
  );
};
