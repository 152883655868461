export const apiUrl = "https://booking.skillstg.co.uk/api/";
// export const apiUrl = "https://cbooking.webstaginghub.com/dev";
//`https://2necsddp66.execute-api.eu-west-2.amazonaws.com/dev/`;

export const statesOptions = {
  GB: [
    "Aberdeen City",
    "Antrim and Newtownabbey",
    "Ards and North Down",
    "Argyll and Bute",
    "Barking and Dagenham",
    "Barnet",
    "Barnsley",
    "Bath and North East Somerset",
    "Bedford",
    "Belfast",
    "Bexley",
    "Birmingham",
    "Blackburn with Darwen",
    "Blackpool",
    "Blaenau Gwent",
    "Bolton",
    "Bournemouth",
    "Bracknell Forest",
    "Bradford",
    "Brent",
    "Bridgend",
    "Brighton and Hove",
    "Bristol, City of",
    "Bromley",
    "Buckinghamshire",
    "Bury",
    "Caerphilly",
    "Calderdale",
    "Cambridgeshire",
    "Camden",
    "Cardiff",
    "Carmarthenshire",
    "Causeway Coast and Glens",
    "Central Bedfordshire",
    "Ceredigion",
    "Cheshire East",
    "Cheshire West and Chester",
    "Clackmannanshire",
    "Conwy",
    "Cornwall",
    "Coventry",
    "Croydon",
    "Cumbria",
    "Darlington",
    "Denbighshire",
    "Derby",
    "Derbyshire",
    "Derry and Strabane",
    "Devon",
    "Doncaster",
    "Dorset",
    "Dudley",
    "Dumfries and Galloway",
    "Dundee City",
    "Durham County",
    "Ealing",
    "East Ayrshire",
    "East Dunbartonshire",
    "East Lothian",
    "East Renfrewshire",
    "East Riding of Yorkshire",
    "East Sussex",
    "Edinburgh, City of",
    "Eilean Siar",
    "Enfield",
    "England",
    "England and Wales",
    "Essex",
    "Falkirk",
    "Fermanagh and Omagh",
    "Fife",
    "Flintshire",
    "Gateshead",
    "Glasgow City",
    "Gloucestershire",
    "Great Britain",
    "Greenwich",
    "Gwynedd",
    "Hackney",
    "Halton",
    "Hammersmith and Fulham",
    "Hampshire",
    "Haringey",
    "Harrow",
    "Hartlepool",
    "Havering",
    "Herefordshire",
    "Hertfordshire",
    "Highland",
    "Hillingdon",
    "Hounslow",
    "Inverclyde",
    "Isle of Anglesey",
    "Isle of Wight",
    "Isles of Scilly",
    "Islington",
    "Kensington and Chelsea",
    "Kent",
    "Kingston upon Hull",
    "Kingston upon Thames",
    "Kirklees",
    "Knowsley",
    "Lambeth",
    "Lancashire",
    "Leeds",
    "Leicester",
    "Leicestershire",
    "Lewisham",
    "Lincolnshire",
    "Lisburn and Castlereagh",
    "Liverpool",
    "London, City of",
    "Luton",
    "Manchester",
    "Medway",
    "Merthyr Tydfil",
    "Merton",
    "Mid and East Antrim",
    "Mid Ulster",
    "Middlesbrough",
    "Midlothian",
    "Milton Keynes",
    "Monmouthshire",
    "Moray",
    "Neath Port Talbot",
    "Newcastle upon Tyne",
    "Newham",
    "Newport",
    "Newry, Mourne and Down",
    "Norfolk",
    "North Ayrshire",
    "North East Lincolnshire",
    "North Lanarkshire",
    "North Lincolnshire",
    "North Somerset",
    "North Tyneside",
    "North Yorkshire",
    "Northamptonshire",
    "Northern Ireland",
    "Northumberland",
    "Nottingham",
    "Nottinghamshire",
    "Oldham",
    "Orkney Islands",
    "Oxfordshire",
    "Pembrokeshire",
    "Perth and Kinross",
    "Peterborough",
    "Plymouth",
    "Poole",
    "Portsmouth",
    "Powys",
    "Reading",
    "Redbridge",
    "Redcar and Cleveland",
    "Renfrewshire",
    "Rhondda, Cynon, Taff",
    "Richmond upon Thames",
    "Rochdale",
    "Rotherham",
    "Rutland",
    "Salford",
    "Sandwell",
    "Scotland",
    "Scottish Borders, The",
    "Sefton",
    "Sheffield",
    "Shetland Islands",
    "Shropshire",
    "Slough",
    "Solihull",
    "Somerset",
    "South Ayrshire",
    "South Gloucestershire",
    "South Lanarkshire",
    "South Tyneside",
    "Southampton",
    "Southend-on-Sea",
    "Southwark",
    "St. Helens",
    "Staffordshire",
    "Stirling",
    "Stockport",
    "Stockton-on-Tees",
    "Stoke-on-Trent",
    "Suffolk",
    "Sunderland",
    "Surrey",
    "Sutton",
    "Swansea",
    "Swindon",
    "Tameside",
    "Telford and Wrekin",
    "Thurrock",
    "Torbay",
    "Torfaen",
    "Tower Hamlets",
    "Trafford",
    "United Kingdom",
    "Vale of Glamorgan, The",
    "Wakefield",
    "Wales",
    "Walsall",
    "Waltham Forest",
    "Warrington",
    "Warwickshire",
    "West Berkshire",
    "West Dunbartonshire",
    "West Lothian",
    "West Sussex",
    "Westminster",
    "Wigan",
    "Wiltshire",
    "Windsor and Maidenhead",
    "Wirral",
    "Wokingham",
    "Wolverhampton",
    "Worcestershire",
    "Wrexham",
    "York",
  ],
  IE: [
    "Carlow",
    "Cavan",
    "Cork",
    "Donegal",
    "Dublin",
    "Galway",
    "Kerry",
    "Kildare",
    "Kilkenny",
    "Laois",
    "Leitrim",
    "Limerick",
    "Longford",
    "Louth",
    "Mayo",
    "Meath",
    "Monaghan",
    "Offaly",
    "Roscommon",
    "Sligo",
    "Tipperary",
    "Waterford",
    "Westmeath",
    "Wexford",
    "Wicklow",
  ],
};

export const ourCourses = [
  {
    title: "View All New Entrant Courses",
    link: "https://www.skillstg.co.uk/category/new-entrant-gas-engineer-courses/",
    icon: "entrant_courses.svg",
  },
  {
    title: "View All Domestic Gas Courses",
    link: "https://www.skillstg.co.uk/category/domestic-gas/",
    icon: "domestic_gas.svg",
  },
  {
    title: "View All Plumbing Courses",
    link: "https://www.skillstg.co.uk/category/plumbing-courses/",
    icon: "plumbing.svg",
  },
  {
    title: "View All Commercial Gas Courses",
    link: "https://www.skillstg.co.uk/category/commercial-gas/",
    icon: "commercial_gas.svg",
  },
  {
    title: "View All Electrical Courses",
    link: "https://www.skillstg.co.uk/category/electrician-training-courses/",
    icon: "electrical.svg",
  },
  {
    title: "View All LPG Courses",
    link: "https://www.skillstg.co.uk/category/lpg/",
    icon: "lpg.svg",
  },
  {
    title: "View All Metering Courses",
    link: "https://www.skillstg.co.uk/category/metering/",
    icon: "smart_metering.svg",
  },
  {
    title: "View All Oil Courses",
    link: "https://www.skillstg.co.uk/category/oil/",
    icon: "oil.svg",
  },
  {
    title: "View All First Aid Training Courses",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/",
    icon: "Face.svg",
  },
  {
    title: "View All Renewable Courses",
    link: "https://www.skillstg.co.uk/category/renewable-courses/",
    icon: "diversetasks.svg",
  },
  {
    title: "View All IOSH Managing Safely Courses",
    link: "https://www.skillstg.co.uk/category/iosh-managing-safely-courses/",
    icon: "challenges.svg",
  },
];

export const firstAidCourses = [
  {
    title: "Aberdeen",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/aberdeen/",
  },
  {
    title: "Belfast",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/belfast/",
  },
  {
    title: "Birmingham",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/birmingham/",
  },
  {
    title: "Bournemouth",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/bournemouth/",
  },
  {
    title: "Bradford",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/bradford/",
  },
  {
    title: "Brighton",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/brighton/",
  },
  {
    title: "Bristol",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses-bristol/",
  },
  {
    title: "Cardiff",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/cardiff/",
  },
  {
    title: "Coventry",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/coventry/",
  },
  {
    title: "Derby",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/derby/",
  },
  {
    title: "Dudley",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/dudley/",
  },
  {
    title: "Dundee",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/dundee/",
  },
  {
    title: "East Kilbride",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/east-kilbride/",
  },
  {
    title: "Edinburgh",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/edinburgh/",
  },
  {
    title: "Fife",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/fife-dunfermline/",
  },
  {
    title: "Greenock",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/greenock/",
  },
  {
    title: "Glasgow",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/glasgow/",
  },
  {
    title: "Hull",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/hull/",
  },
  {
    title: "Kilmarnock, Ayrshire",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/kilmarnock/",
  },
  {
    title: "Leeds",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/leeds/",
  },
  {
    title: "Leicester",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/leicester/",
  },
  {
    title: "Leith",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/leith/",
  },
  {
    title: "Liverpool",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/liverpool/",
  },
  {
    title: "Manchester",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/manchester/",
  },
  {
    title: "Newcastle",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/newcastle/",
  },
  {
    title: "Nottingham",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/nottingham/",
  },
  {
    title: "Oldbury, West Midlands",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/oldbury/",
  },
  {
    title: "Paisley",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/paisley/",
  },
  {
    title: "Perth",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/perth/",
  },
  {
    title: "Portsmouth",
    link: "https://www.skillstg.co.uk/first-aid-training-courses/portsmouth/",
  },
  {
    title: "Sheffield",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/sheffield/",
  },
  {
    title: "Solihull",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/solihull/",
  },
  {
    title: "Stirling",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/stirling/",
  },
  {
    title: "Wolverhampton",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/wolverhampton/",
  },
  {
    title: "Walsall",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/walsall/",
  },
  {
    title: "West Bromwich",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/west-bromwich/",
  },
  {
    title: "Worcester",
    link: "https://www.skillstg.co.uk/first-aid-training-courses/worcester/",
  },
  {
    title: "York",
    link: "https://www.skillstg.co.uk/category/first-aid-training-courses/york/",
  },
];

export const newEntrantGasEngineerCourses = [
  {
    title: "Edinburgh",
    link: "https://www.skillstg.co.uk/category/new-entrant-gas-engineer-courses/edinburgh/",
  },
  {
    title: "Glasgow",
    link: "https://www.skillstg.co.uk/category/new-entrant-gas-engineer-courses/glasgow/",
  },
  {
    title: "Liverpool",
    link: "https://www.skillstg.co.uk/category/new-entrant-gas-engineer-courses/liverpool/",
  },
  {
    title: "Manchester",
    link: "https://www.skillstg.co.uk/category/new-entrant-gas-engineer-courses/manchester/",
  },
];
