import React, { memo } from "react";

export const Loader = memo(({ styles, className }) => {
  return (
    <div className="flex">
      <div
        className={`lds-spinner mx-auto my-4 ${className ? className : ""}`}
        style={styles}
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
});
