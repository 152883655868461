import React, { useEffect, useState } from "react";
import { ReactComponent as PaginationPrevIcon } from "../../../assets/img/icons/arrow-prev-pagination.svg";
import { ReactComponent as PaginationNextIcon } from "../../../assets/img/img-09-10-23/arrow-next-pagination-2.svg";
import { Course } from "./Course";
import { useSelector, useDispatch } from "react-redux";
import { Loader } from "../../../components/ui/Loader";
import { useSearchParams } from "react-router-dom";
import { formatDate } from "../../../helpers";
import { BookCourseModal } from "../../../components/modals/BookCourseModal";

export const Courses = () => {
  const grouped = useSelector((state) => state.courses.grouped);

  const coursesLoading = useSelector((state) => state.courses.coursesLoading);

  const [bookCourseModalOpen, setBookCourseModalOpen] = useState(false);

  const [params] = useSearchParams();
  const [filteredCourses, setFilteredCourses] = useState([...grouped]);
  const [itemsOnPage, setItemsOnPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    filterCourses();
  }, [grouped, coursesLoading]);

  const filterCourses = () => {
    let copy = [...grouped];
    const filteredGroupedCopy = copy?.map((item) => {
      const schedules = item?.schedules
        ?.filter((item) => {
          let aDate = +params?.get("start_date") || Date.now();
          let bDate = new Date(item.start_date * 1000);
          return new Date(aDate) <= new Date(bDate);
        })
        .sort((a, b) => a.start_date - b.start_date);
      return {
        center: item?.center,
        schedules,
      };
    });

    console.log("filteredGroupedCopy:", filteredGroupedCopy);

    setFilteredCourses(filteredGroupedCopy);
  };

  const getPaginatedGroup = () => {
    let paginatedGroup = [...filteredCourses].slice(
      (currentPage - 1) * itemsOnPage,
      currentPage * itemsOnPage,
    );
    return paginatedGroup;
  };

  const closestToCourses = getPaginatedGroup().filter(
    (item) =>
      item?.center?.name?.split(" ")[0] ===
      getPaginatedGroup()[0]?.center?.name?.split(" ")[0],
  );
  const closestToCoursesCities = closestToCourses?.map(
    (item) => item?.center?.name?.split(" ")[0],
  );

  useEffect(() => {
    if (document.querySelector("#courses")) {
      document.querySelector("#courses").scrollIntoView({
        block: "center",
        inline: "center",
        behavior: "smooth",
      });
    }
  }, []);
  if (coursesLoading) {
    return <Loader />;
  }
  if (!getPaginatedGroup()?.length) {
    return null;
  }

  return (
    <>
      <div id="courses"></div>
      {filteredCourses.length > 0 && (
        <div className="pill-message text-center mb-15">
          <p>
            We have shown you the {filteredCourses?.length} closest venues based
            on your estimated location.
          </p>
        </div>
      )}
      <div className="items items-24">
        <div className="result-search">
          {getPaginatedGroup()[0] && (
            <div className="result-search-title">
              <h4 className="heading-4">Closest to {params.get("location")}</h4>
            </div>
          )}
          <div className="result-search-body">
            <ul className="result-search-list">
              {closestToCourses?.map((item, index) => {
                return (
                  <li key={index}>
                    <Course group={item} closest={params.get("location")} />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>

      <div className="pill-message-2 mb-24">
        <p>
          If suitable dates are not available please contact our customer
          service team on{" "}
          <a href="tel:+0808 164 2780" className="btn tdu color-text--700">
            0808 164 2780
          </a>
          <br />
          For courses that are sold out, we can add you to our waiting list for
          any cancellations.
        </p>
      </div>

      <div className="items items-24">
        <div className="result-search">
          {getPaginatedGroup().length > 0 && (
            <div className="result-search-title">
              <h4 className="heading-4">Check other locations</h4>
            </div>
          )}
          <div className="result-search-body">
            <ul className="result-search-list">
              {getPaginatedGroup().map((group, index) => {
                // closestToCoursesCities
                // if (index !== 0)
                if (
                  !closestToCoursesCities.includes(
                    group?.center?.name?.split(" ")[0],
                  )
                )
                  return (
                    <li key={index + group.center.name}>
                      <Course
                        group={group}
                        bookOnsite={() => {
                          setBookCourseModalOpen(true);
                        }}
                      />
                    </li>
                  );
              })}
            </ul>
          </div>
          {Math.ceil(grouped?.length / itemsOnPage) > 1 && (
            <div className="result-search-footer">
              <div className="pagination-no-responsive justify-content-center">
                <button
                  className={`btn ${currentPage === 1 ? "btn--didabled" : ""}`}
                  onClick={() => setCurrentPage((prev) => (prev = prev - 1))}
                >
                  <span className="btn-icon me-2 d-flex">
                    <PaginationPrevIcon />
                  </span>
                  Prev
                </button>
                <ul className="pagination-list">
                  {Array.from(
                    { length: Math.ceil(grouped?.length / itemsOnPage) },
                    (_, index) => (
                      <li key={index}>
                        <button
                          className={`pagination-link ${
                            index + 1 === currentPage ? "active" : ""
                          }`}
                          onClick={() => setCurrentPage(index + 1)}
                        >
                          {index + 1}
                        </button>
                      </li>
                    ),
                  )}
                </ul>
                <button
                  className={`btn ${
                    currentPage === Math.ceil(grouped?.length / itemsOnPage)
                      ? "btn--didabled"
                      : ""
                  }`}
                  onClick={() => setCurrentPage((prev) => (prev = prev + 1))}
                >
                  Next
                  <span className="btn-icon ms-2 d-flex">
                    <PaginationNextIcon />
                  </span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {bookCourseModalOpen ? (
        <BookCourseModal
          isOpen={bookCourseModalOpen}
          close={() => setBookCourseModalOpen(false)}
        />
      ) : null}
    </>
  );
};
