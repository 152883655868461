import { createSlice } from "@reduxjs/toolkit";
import { fetchCourses, postBooking, getURLTags } from "../thunks/coursesThunk";

const initialState = {
  tags: getURLTags(),
  access_token: "1231323",
  instance_url: "/url.com",
  coursesLoading: false,
  grouped: [],
  courses: [],
  centres: [],
  not_found: false,
  user_city: window.localStorage.getItem("user_city"),
  selectedCourse: null,
  selectedCourseId: null,
  bookingType: "single",
  learners: [],
  billingData: {
    country: "",
    state: "",
    city: "",
    street: "",
    postalCode: "",
    companyName: "",
    comments: "",
  },
};

export const coursesSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    selectCourse: (state, { payload }) => {
      if (payload !== state.selectedCourseId) {
        let findedCourse = state.courses.find(
          (item) => String(item.internal_id) === String(payload),
        );
        if (findedCourse) {
          state.selectedCourse = findedCourse;
          state.selectedCourseId = payload;
        }
      }
    },
    addLearner: (state, { payload }) => {
      state.learners.splice(payload?.index ?? 0, 1, payload?.item);
    },
    setLearners: (state, { payload }) => {
      state.learners = payload;
    },
    setBillingData: (state, { payload }) => {
      state.billingData = payload;
    },
    removeLearner: (state, { payload }) => {
      const copy = [...state.learners];
      copy.splice(payload, 1);
      state.learners = copy;
    },
    setBookingType: (state, { payload }) => {
      if (payload === "single") {
        state.learners = state.learners.slice(0, 1);
      }
      state.bookingType = payload;
    },
    changeCoursesLoading: (state, action) => {
      state.coursesLoading = action.payload;
    },
    clearSearchResult: (state, action) => {
      state.courses = [];
      state.centres = [];
      state.selectedCourse = null;
      state.selectedCourseId = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchCourses.fulfilled, (state, action) => {
      if (action?.payload === null) {
        state.courses = [];
        state.centres = [];
        state.not_found = true;
      } else {
        state.not_found = false;
      }

      if (action?.payload?.grouped) {
        if (action?.payload?.grouped.length === 0) {
          state.courses = [];
          state.centres = [];
          state.not_found = true;

          state.coursesLoading = false;
        }

        state.courses = [
          ...action.payload.grouped.map((item) => item.schedules).flat(),
        ].map((item) => {
          let newItem = { ...item };
          let startDate = new Date(newItem.start_date * 1000);
          newItem.formattedStartDate = `${startDate.getDate()} ${startDate
            .toLocaleString("en-US", { month: "short" })
            .toLocaleUpperCase()} ${startDate.getFullYear()}`;
          return newItem;
        });

        state.centres = action.payload.grouped.map((item) => item.center);
        state.grouped = action.payload.grouped;

        if (
          action?.payload?.user_city &&
          state.defaultCity !== action.payload.user_city &&
          action.payload.user_city !== "Unknown"
        ) {
          state.defaultCity = action.payload.user_city;
        }
      }

      state.coursesLoading = false;
    });

    builder.addCase(fetchCourses.pending, (state, action) => {
      state.coursesLoading = true;
    });

    builder.addCase(fetchCourses.rejected, (state, action) => {
      state.coursesLoading = false;
    });

    builder.addCase(postBooking.fulfilled, (state, { payload }) => {
      state.coursesLoading = false;
    });
    builder.addCase(postBooking.pending, (state, action) => {
      state.coursesLoading = true;
    });
    builder.addCase(postBooking.rejected, (state, { payload }) => {
      state.coursesLoading = false;
    });
  },
});

export const {
  selectCourse,
  changeCoursesLoading,
  setBookingType,
  addLearner,
  removeLearner,
  setLearners,
  setBillingData,
  clearSearchResult,
} = coursesSlice.actions;

export default coursesSlice.reducer;
