import React, { useEffect, useState } from "react";
import { Accreditations } from "../../components/Accreditations";
import { Guarantees } from "../../components/Guarantees";
import { useParams } from "react-router-dom";
import { OrderDetails } from "./OrderDetails/OrderDetails";

import { services } from "../../data/data";
import { Steps } from "./Steps/Steps";
import { selectCourse } from "../../redux/slices/coursesSlice";
import { fetchCourses } from "../../redux/thunks/coursesThunk";
import { useDispatch, useSelector } from "react-redux";

export const Booking = () => {
  const courses = useSelector((state) => state.courses.courses);
  const coursesLoading = useSelector((state) => state.courses.coursesLoading);
  const selectedCourse = useSelector((state) => state.courses.selectedCourse);

  const dispatch = useDispatch();

  const { internal_id, p_code } = useParams();

  useEffect(() => {
    if (!courses.length)
      dispatch(fetchCourses({ p_code: p_code ? p_code : "DOM-PAK-REA" }));
  }, []);

  useEffect(() => {
    if (!coursesLoading) {
      dispatch(selectCourse(internal_id));
    }
  }, [coursesLoading, courses]);

  return (
    <div className="wrapper header-fixed bg-styles-top-right">
      <main className="content">
        <section className="section section-40 pt-mob-0 pb-mob-0 bg-white">
          <div className="container container-lg">
            <div className="row g-24 mb-60 mb-mob-30">
              {!selectedCourse ? (
                <div>not found</div>
              ) : (
                <>
                  <div className="col-lg-6">
                    <Steps />
                  </div>
                  <OrderDetails course={selectedCourse} />
                </>
              )}
            </div>
            <div className="d-lg-none">
              <Guarantees row={false} />
            </div>
            <Accreditations />
          </div>
        </section>
      </main>
    </div>
  );
};
