import React from "react";
import logo from "../assets/img/footer-logo.svg";
import bgImage from "../assets/img/images/footer-image-bg.svg";

import { ReactComponent as SearchIcon } from "../assets/img/icons/search-icon.svg";

export const Footer = () => {
  return (
    <footer className="footer color-white">
      <div className="container">
        <div
          className="footer__bottom bg-styles-bottom-right"
          style={{
            backgroundImage: `url(${bgImage})`,
          }}
        >
          <div className="d-flex flex-wrap align-items-center mb-2 mb-lg-5">
            <a href="" className="btn mr-40 mb-3">
              <img src={logo} alt="" />
            </a>
            <h5 className="color-white fs-12 mb-3">
              THE UK’S LEADER IN HEATING, ELECTRICAL & UTILITIES TRAINING
              COURSES
            </h5>
          </div>
          <nav className="footer-nav mb-60 mb-lg-30">
            <div className="footer-nav-item">
              <h5 className="footer-nav-title mb-20">Navigation</h5>
              <ul className="footer-nav-list">
                <li>
                  <a href="https://www.skillstg.co.uk/our-courses/">
                    Our Courses
                  </a>
                </li>
                <li>
                  <a href="https://www.skillstg.co.uk/about-us/">About Us</a>
                </li>
                <li>
                  <a href="https://www.skillstg.co.uk/contact-us/">
                    Contact Us
                  </a>
                </li>
                <li>
                  <a href="https://www.skillstg.co.uk/blog/">Resources</a>
                </li>
              </ul>
            </div>
            <div className="footer-nav-item">
              <h5 className="footer-nav-title mb-20">Company</h5>
              <ul className="footer-nav-list">
                <li>
                  <a href="https://www.skillstg.co.uk/privacy-policy/">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="https://www.skillstg.co.uk/stg-complaints-policy.pdf">
                    Complaints Procedure
                  </a>
                </li>
                <li>
                  <a href="https://www.skillstg.co.uk/covid-policy/">
                    Covid Policy
                  </a>
                </li>
                <li>
                  <a href="https://www.skillstg.co.uk/terms-and-conditions/">
                    Booking Terms &amp; Conditions
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-nav-item">
              <h5 className="footer-nav-title mb-20">Get In Touch</h5>
              <ul className="footer-nav-list">
                <li>
                  <span>Paisley</span>
                  <a href="tel:+448081696423" style={{ fontWeight: "300" }}>
                    <span className="d-block">08081696423</span>
                  </a>
                </li>
                <li>
                  <span>Dalgety Bay</span>
                  <a href="tel:+448081696423" style={{ fontWeight: "300" }}>
                    <span className="d-block">08081696423</span>
                  </a>
                </li>
                <li>
                  <a href="mailto:info@skillstg.co.uk">info@skillstg.co.uk</a>
                </li>
              </ul>
            </div>
            <div className="footer-nav-item">
              <h5 className="footer-nav-title mb-20">Find a Course</h5>
              <form className="search-form relative mw-350">
                <input className="input" type="text" placeholder="Search..." />
                <button
                  type="submit"
                  className="btn btn--info btn--sm-rounded rounded ttu search-form__button"
                >
                  <SearchIcon />
                </button>
              </form>
            </div>
          </nav>
          <div className="d-flex align-items-center flex-wrap footer-copyright">
            <p className="fs-12 flex-auto pe-3">
              Copyright &copy; 2022 Skills Training Group. All Rights Reserved.
            </p>

            <ul className="d-flex align-items-center fs-12">
              <li className="mr-30">
                <a href="https://www.skillstg.co.uk/privacy-policy/">
                  Privacy Policy
                </a>
              </li>
              <li className="mr-30">
                <a href="https://www.skillstg.co.uk/">Sitemap</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};
