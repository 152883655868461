import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SelectCustom } from "../../../components/ui/SelectCustom";
import { formatTimestamp, formatTimestampNew, formatPrice } from "../../../helpers";
import { SeeOnMapModal } from "../../../components/modals/SeeOnMapModal";

export const Course = ({ group, closest = null, bookOnsite }) => {
  console.log("Course group:", group);
  const [internal_id, setInternal_id] = useState(
    group?.schedules[0]?.internal_id,
  );
  const [selectedCourse, setSelectedCourse] = useState(group?.schedules[0]);
  const [seeOnMapModalOpen, setSeeOnMapModalOpen] = useState(false);

  useEffect(() => {
    const course = group?.schedules?.find(
      (course) => course?.internal_id === internal_id,
    );
    setSelectedCourse(course);
  }, [internal_id]);

  const ifLessTen = (val) => {
    if (String(val).length < 2) {
      return `0${val}`;
    } else {
      return val;
    }
  };

  const availableDatesOptions = group?.schedules?.map((course) => {
    return {
      value: course?.internal_id,
      label: group?.center?.partner
        ? `${formatTimestampNew(+course?.start_date)}, ${ifLessTen(
            new Date(course?.start_date * 1000).getHours(),
          )}:${ifLessTen(new Date(course?.start_date * 1000).getMinutes())}`
        : formatTimestampNew(+course?.start_date),
    };
  });
// console.log('hiiiiiii')
// console.log(selectedCourse)
  return (
    <div className="card-row">
      <div className="card-row--item items items-10">
      {group?.center?.name != 'Virtual' ? (<div className="laction-info">
          {closest && <div className="location-text">Closest to {closest}</div>}
          <div className="miles location-text">
            {group?.center?.distance ? (
              <span>
                {(Number(group?.center?.distance) * 0.62137119).toFixed(2) +
                  "miles"}
              </span>
            ) : (
              <span>unknown</span>
            )}
          </div>
        </div>) : (<span></span>) }
        {/* {selectedCourse?.name} */}
        <h4 className="heading-4 heading-4-sm">{group?.center?.name}</h4>
        {group?.center?.name != 'Virtual' ? (<p>
          {group?.center?.full_address ||
            `${group?.center?.address_line_1}, ${group?.center?.address_line_3}`}
          {/* {group?.center?.name},  */}
          {/* {group?.center?.address_line_1},{" "}
          {group?.center?.address_line_3} */}
        </p>) : (<span></span>)}
        {group?.center?.name != 'Virtual' ? (<a
          href="#"
          style={{ color: "#09983F" }}
          onClick={(e) => {
            e.preventDefault();
            setSeeOnMapModalOpen(true);
          }}
        >
          See On Map
        </a>) : (<span></span>)}
        <div className="form-group">
          <label className="form-group-title">Pick start date</label>
          <div
            className="select-shadow-wrapp"
            key={`${group?.center?.name}` || "date"}
          >
            <SelectCustom
              options={availableDatesOptions}
              onSelect={(value) => setInternal_id(value)}
            />
          </div>
        </div>
      </div>

      <div className="card-row--item lightcard justify-content-end items items-10">
        <div className="flex-option">
          <h6 className="def-text-1 font-700">Reserve your seat</h6>
          <p className="color-text--success">
            Only <span>{selectedCourse?.space}</span> seats left
          </p>
        </div>
        <h3 className="heading-3 pt-16">
          £{formatPrice(selectedCourse?.price_online)}
          <span className="text-default"> (inc VAT)</span>
        </h3>
        {!group?.center?.partner ? (
          <Link
            to={`/booking/${internal_id}?step=1`}
            className="btn btn-success btn--md radius-5 ttu"
          >
            Book now
          </Link>
        ) : (
          <button
            className="btn btn-success btn--md radius-5 ttu"
            onClick={(e) => {
              e.preventDefault();
              bookOnsite && bookOnsite();
            }}
          >
            Book onsite
          </button>
        )}
      </div>
      {seeOnMapModalOpen ? (
        <SeeOnMapModal
          group={group}
          close={() => {
            setSeeOnMapModalOpen(false);
          }}
        />
      ) : null}
    </div>
  );
};
