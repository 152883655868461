import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AddLearner } from "./AddLearner";
import { BillingDetails } from "./BillingDetails";
import { PaymentDetails } from "./PaymentDetails";
import { PersonalDetails } from "./PersonalDetails";
import { ReviewPersonalDetails } from "./ReviewPersonalDetails";

export const Steps = () => {
  const [params] = useSearchParams();
  const bookingType = useSelector((state) => state.courses.bookingType);
  const learners = useSelector((state) => state.courses.learners);

  const isMultiple = bookingType !== "single";
  const navigate = useNavigate();

  const nextStepHandler = () => {
    const step = Number(params.get("step"));
    console.log("isMultiple:", isMultiple);
    console.log("step:", step);

    if (!isMultiple && step === 2) {
      navigate({
        search: `?step=4`,
      });
      return;
    }
    if (step < 5) {
      navigate({
        search: `?step=${Number(step) + 1}`,
      });
    }
  };
  const prevStepHandler = () => {
    const step = Number(params.get("step"));
    if (!isMultiple && step === 4) {
      navigate({
        search: `?step=2`,
      });
      return;
    }
    if (step > 1) {
      navigate({
        search: `?step=${Number(step) - 1}`,
      });
    } else {
      navigate("..");
    }
  };

  const step = Number(params.get("step"));

  useEffect(() => {
    if (!params.get("step") || (!learners.length && step !== 1)) {
      // console.log('params.get("step"), learners.length:', params.get("step"), learners.length);
      navigate({
        search: `?step=1`,
      });
    }
  }, []);

  return (
    <>
      {step === 1 && (
        <PersonalDetails
          nextHandler={nextStepHandler}
          prevHandler={prevStepHandler}
        />
      )}
      {step === 2 && (
        <BillingDetails
          nextHandler={nextStepHandler}
          prevHandler={prevStepHandler}
        />
      )}
      {step === 3 && (
        <AddLearner
          nextHandler={nextStepHandler}
          prevHandler={prevStepHandler}
        />
      )}
      {step === 4 && (
        <ReviewPersonalDetails
          nextHandler={nextStepHandler}
          prevHandler={prevStepHandler}
        />
      )}
      {step === 5 && (
        <PaymentDetails
          nextHandler={nextStepHandler}
          prevHandler={prevStepHandler}
        />
      )}
    </>
  );
};
