import React, { useState, useEffect } from "react";

export const SeeOnMapModal = ({ close, group }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const closeAction = () => {
    document.querySelector("body").classList.remove("lock");
    setModalOpen(false);
    setTimeout(() => {
      close && close();
    }, 300);
  };

  const seeOnMap = () => {
    const google = window?.google;
    async function showCenter(lat, lng, name, full_address) {
      const { Map, Marker, InfoWindow } =
        await google.maps.importLibrary("maps");

      let map = new Map(document.getElementById("gmap1"), {
        center: { lat: lat, lng: lng },
        zoom: 17,
      });

      const marker = new google.maps.Marker({
        position: { lat: lat, lng: lng },
        map,
        title: name,
      });

      const infowindow = new google.maps.InfoWindow({
        content: `<b>${name}</b><br>${full_address}`,
        ariaLabel: name,
      });

      infowindow.open({
        anchor: marker,
        map,
      });
    }

    showCenter(
      group?.center?.center_lat,
      group?.center?.center_lng,
      group?.center?.name,
      group?.center?.full_address,
    );
  };

  useEffect(() => {
    document.querySelector("body").classList.add("lock");

    setTimeout(() => {
      setModalOpen(true);
      seeOnMap();
    }, 300);
  }, []);

  return (
    <div className={`modal ${modalOpen ? "modal--show" : ""}`}>
      <div className="modal__dialog modal__dialog--590">
        <div className="modal__content">
          <div className="modal__header">
            <div className="items items-5">
              <h5 className="heading-5 color-white">{group?.center?.name}</h5>
              <p className="def-text-1 color-white">
                {group?.center?.full_address}
              </p>
            </div>

            <a
              href="#"
              className="btn btn-close"
              data-close-modal=""
              onClick={(e) => {
                e.preventDefault();
                closeAction();
              }}
            >
              <span className="ico ico-15">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.32713 13.8318C0.983892 13.8517 0.646328 13.7376 0.385622 13.5135C-0.128541 12.9963 -0.128541 12.1609 0.385622 11.6437L11.6441 0.385178C12.1789 -0.115229 13.018 -0.0874119 13.5184 0.447363C13.9709 0.930957 13.9973 1.67425 13.5802 2.18868L2.25537 13.5135C1.99803 13.7344 1.66588 13.8483 1.32713 13.8318Z"
                    fill="white"
                  />
                  <path
                    d="M12.5721 13.8318C12.2242 13.8303 11.8908 13.6922 11.6438 13.4472L0.385271 2.18866C-0.0910742 1.6324 -0.0263126 0.795263 0.529948 0.318874C1.02642 -0.106291 1.75863 -0.106291 2.25506 0.318874L13.5799 11.5774C14.1145 12.0779 14.1422 12.9171 13.6416 13.4518C13.6217 13.473 13.6011 13.4936 13.5799 13.5135C13.3026 13.7547 12.9376 13.8699 12.5721 13.8318Z"
                    fill="white"
                  />
                </svg>
              </span>
            </a>
          </div>
          <div className="modal__body py-25">
            <div id="gmap1" style={{ width: "100%", height: "500px" }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};
