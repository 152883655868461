import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";

import { MainLayout } from "../layouts/MainLayout";
import { CoursePage } from "./CoursePage/CoursePage";
import { Booking } from "./Booking/Booking";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<MainLayout />}>
        <Route path="/" element={<CoursePage />} />
        <Route path="/course/:p_code" element={<CoursePage />} />
        {/* <Route index element={<CoursePage />} /> */}
        <Route path="/booking/:internal_id" element={<Booking />} />
      </Route>
    </>,
  ),
  { basename: "/" },
  // ), { basename: "/skillstg-new-react" }
);
