export const services = [
  {
    id: 1,
    name: `Ibis Coventry South`,
    address: `Whitley Business Park, Coventry, CV3 4BJ`,
    emptySeats: 8,
    price: 94.8,
    distance: 95.73,
    location: `London`,
  },
  {
    id: 2,
    name: `Manor House Community Centre`,
    address: `Leicester - Haddenham Road, Leicester, LE3 2BG`,
    emptySeats: 8,
    price: 94.8,
    distance: 88.4,
    location: `Germany`,
  },
  {
    id: 3,
    name: `Solihull - West Warwickshire Sports Complex`,
    address: `Solihull, B91 1DA`,
    emptySeats: 8,
    price: 94.8,
    distance: 95.73,
    location: `France`,
  },
];

export const FAQs = [
  {
    id: 1,
    question: "What’s your cancellation policy?",
    answer: `To cancel, please email us at <a href="mailto:info@skillstg.co.uk">info@skillstg.co.uk</a>. Please provide your name, home address, details of the order, and where available, your phone number and email address. For cancellations within 20 working days of the start date, the course cost is non-refundable.
      <br />
      <br />
      Full terms and conditions can be found <a href="https://www.skillstg.co.uk/terms-and-conditions/" target="_blank">here</a>.`,
  },
  {
    id: 2,
    question: "What if your course doesn’t fill?",
    answer: `Customer satisfaction comes first, so we always go ahead with our courses, even if they don’t fill.`,
  },
  {
    id: 3,
    question: "When will I receive my receipt and joining instructions?",
    answer: `You will receive a booking confirmation and joining instructions automatically within 10 minutes of booking your course. A VAT invoice will then be sent manually by a member of our team. If it’s the weekend, please give them until Monday to send this over to you before reaching out to them.`,
  },
  {
    id: 4,
    question: "What is the latest date I can book a course?",
    answer: `If the course has no requirement for e-learning prior to the start date, you can book a course at any time. However, if you need to complete e-learning prior to the start date, please give yourself enough time to undertake this.`,
  },
  // {
  //   id: 5,
  //   question: "Do you think I would be able to go self-employed right away?",
  //   answer: `Many of our students have started their own businesses. If you already have a technical background, it would be much easier. Even if you aren't from a technical background, you may go self-employed; however, we recommend working for an employer for at least 6 months to gain experience.`,
  // },
  // {
  //   id: 6,
  //   question: "How do I book?",
  //   answer: `Call one of our customer service team on 0808 164 2780 and they will walk you through the booking process.`,
  // },
];
