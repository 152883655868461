import React, { memo, useState } from "react";
import Efawlogo from "../assets/img/logo.svg";
import { ReactComponent as ArrowDownIcon } from "../assets/img/icons/arrow-next-light-blue.svg";
import { ReactComponent as TrustPilotLogo } from "../assets/img/logo-2.svg";
import { ReactComponent as SearchIcon } from "../assets/img/icons/search-icon.svg";
import { ReactComponent as PhoneIcon } from "../assets/img/icons/phone-icon.svg";
import {
  ourCourses,
  firstAidCourses,
  newEntrantGasEngineerCourses,
} from "../data/constants";

import { Link } from "react-router-dom";
export const Header = memo(() => {
  const [isMenuActive, setIsMenuActive] = useState();
  const [searchActive, setSearchActive] = useState(false);
  const [headerHoverBg, setHeaderHoverBg] = useState(false);
  const toggleMenu = () => setIsMenuActive((prevValue) => !prevValue);
  const [headerMobileOpens, setHeaderMobileOpens] = useState({
    ourCourses: false,
    coverageArea: false,
    aboutUs: false,
    resources: false,
  });
  const [dropdownOpen, setDropdownOpen] = useState(null);

  const showOverlay = (value) => {
    setHeaderHoverBg(value);
  };

  const toggleMobile = (e, name) => {
    if (+window.innerWidth < 1200) {
      e.preventDefault();

      setHeaderMobileOpens((prevState) => {
        return {
          ...prevState,
          [name]: !prevState[name],
        };
      });

      setDropdownOpen(null);
    }
  };

  const dropdownToggle = (e, index) => {
    if (+window.innerWidth < 1200) {
      e.preventDefault();
      setDropdownOpen(dropdownOpen === index ? null : index);
    }
  };

  return (
    <>
      <header className="header">
        <nav className="container">
          <div className="header__body">
            {/* <Link to="/course/EFW" className="header__logo"> */}
            <a href="https://www.skillstg.co.uk/" className="header__logo">
              {/* <Efawlogo /> */}
              <img src={Efawlogo} />
            </a>
            <div className="header__call">
              <a href="tel:08081642780" className="header__call-button">
                <PhoneIcon />
                <span>Call Now</span>
              </a>
            </div>
            <div
              className="header__burger-wrapper"
              onClick={() => toggleMenu()}
            >
              <div
                style={{ cursor: "pointer" }}
                className={`header__burger ${isMenuActive ? "active" : ""}`}
              >
                <span></span>
              </div>
            </div>
            <nav className={`header__menu ${isMenuActive ? "active" : ""}`}>
              <ul className="header__list">
                <li
                  className="has-child menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children"
                  onMouseOver={() => {
                    showOverlay(true);
                  }}
                  onMouseLeave={() => {
                    showOverlay(false);
                  }}
                >
                  <a
                    href=""
                    className="header__link"
                    onClick={(e) => toggleMobile(e, "ourCourses")}
                  >
                    <span>Our Courses</span>
                    <div className="open_sub_menu_arrow">
                      <ArrowDownIcon />
                    </div>
                  </a>
                  <ul
                    className={`sub-menu ${
                      headerMobileOpens.ourCourses ? "open" : ""
                    }`}
                  >
                    <li>
                      <a
                        href="https://www.skillstg.co.uk/our-courses/"
                        className="nav-big-submenu-title"
                      >
                        Course Categories
                      </a>
                      <ul>
                        <li
                          className="mobile-menu-back"
                          onClick={(e) => toggleMobile(e, "ourCourses")}
                        >
                          Back
                        </li>
                        {ourCourses.map((link, index) => {
                          return (
                            <li
                              className="mh menu-course-category desktop-height-78"
                              key={index}
                            >
                              <div className="col-xs-12 col-sm-3 p-left--zero w3_bg">
                                <a href={link.link}>
                                  <img src={`/header-icons/${link.icon}`} />
                                </a>
                              </div>
                              <div className="col-xs-12 col-sm-9 p--zero w3_bg">
                                <a
                                  href={link.link}
                                  className="dropdown-toggle "
                                >
                                  <h5>{link.title}</h5>
                                </a>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  </ul>
                </li>
                <li
                  className="has-child menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-object-page-two"
                  onMouseOver={() => {
                    showOverlay(true);
                  }}
                  onMouseLeave={() => {
                    showOverlay(false);
                  }}
                >
                  <a
                    href=""
                    className="header__link"
                    onClick={(e) => toggleMobile(e, "coverageArea")}
                  >
                    <span>Coverage Area</span>
                    <div className="open_sub_menu_arrow">
                      <ArrowDownIcon />
                    </div>
                  </a>
                  <ul
                    className={`sub-menu ${
                      headerMobileOpens.coverageArea ? "open" : ""
                    }`}
                  >
                    <li>
                      <a
                        href="https://www.skillstg.co.uk/our-courses/"
                        className="nav-big-submenu-title"
                      >
                        Course Locations
                      </a>
                      <ul>
                        <li
                          className="mobile-menu-back"
                          onClick={(e) => toggleMobile(e, "coverageArea")}
                        >
                          Back
                        </li>
                        <li className="mh menu-course-category desktop-height-36">
                          <div className="col-xs-12 col-sm-12 p--zero dropdown w3_bg">
                            <a
                              href="https://www.skillstg.co.uk/category/new-entrant-gas-engineer-courses/"
                              className="dropdown-toggle haschild"
                              onClick={(e) => dropdownToggle(e, 0)}
                            >
                              <h5>New Entrant Gas Engineer Courses</h5>
                            </a>
                            <ul
                              className={`sub-menu-two dropdown-menu dropdown-menu-right ${
                                dropdownOpen === 0 ? "open" : ""
                              }`}
                            >
                              {newEntrantGasEngineerCourses.map(
                                (link, index) => {
                                  return (
                                    <li key={index}>
                                      <img
                                        src={`/header-icons/pin-icon.svg`}
                                        className="icon"
                                        alt=""
                                      />
                                      <a href={link.link}>{link.title}</a>
                                    </li>
                                  );
                                },
                              )}
                            </ul>
                          </div>
                        </li>
                        <li className="mh menu-course-category desktop-height-36">
                          <div className="col-xs-12 col-sm-12 p--zero dropdown w3_bg">
                            <a
                              href="https://www.skillstg.co.uk/category/plumbing-courses/"
                              className="dropdown-toggle haschild"
                              onClick={(e) => dropdownToggle(e, 1)}
                            >
                              <h5>Plumbing Courses</h5>
                            </a>
                            <ul
                              className={`sub-menu-two dropdown-menu dropdown-menu-right ${
                                dropdownOpen === 1 ? "open" : ""
                              }`}
                            >
                              <li>
                                <img
                                  src={`/header-icons/pin-icon.svg`}
                                  className="icon"
                                  alt=""
                                />
                                <a href="https://www.skillstg.co.uk/category/plumbing-courses/edinburgh/">
                                  Edinburgh
                                </a>
                              </li>
                              <li>
                                <img
                                  src={`/header-icons/pin-icon.svg`}
                                  className="icon"
                                  alt=""
                                />
                                <a href="https://www.skillstg.co.uk/category/plumbing-courses/glasgow/">
                                  Glasgow
                                </a>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li className="mh menu-course-category desktop-height-36">
                          <div className="col-xs-12 col-sm-12 p--zero dropdown w3_bg">
                            <a
                              href="https://www.skillstg.co.uk/category/electrician-training-courses/"
                              className="dropdown-toggle haschild"
                              onClick={(e) => dropdownToggle(e, 2)}
                            >
                              <h5>Electrician Courses</h5>
                            </a>
                            <ul
                              className={`sub-menu-two dropdown-menu dropdown-menu-right ${
                                dropdownOpen === 2 ? "open" : ""
                              }`}
                            >
                              <li>
                                <img
                                  src={`/header-icons/pin-icon.svg`}
                                  className="icon"
                                  alt=""
                                />
                                <a href="https://www.skillstg.co.uk/category/electrician-training-courses/edinburgh/">
                                  Edinburgh
                                </a>
                              </li>
                              <li>
                                <img
                                  src={`/header-icons/pin-icon.svg`}
                                  className="icon"
                                  alt=""
                                />
                                <a href="https://www.skillstg.co.uk/category/electrician-training-courses/glasgow/">
                                  Glasgow
                                </a>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li className="mh menu-course-category desktop-height-36">
                          <div className="col-xs-12 col-sm-12 p--zero dropdown w3_bg">
                            <a
                              href="https://www.skillstg.co.uk/category/metering/"
                              className="dropdown-toggle "
                            >
                              <h5>Metering Courses</h5>
                            </a>
                          </div>
                        </li>
                        <li className="mh menu-course-category desktop-height-36">
                          <div className="col-xs-12 col-sm-12 p--zero dropdown w3_bg">
                            <a
                              href="https://www.skillstg.co.uk/category/first-aid-training-courses/"
                              className="dropdown-toggle haschild"
                              onClick={(e) => dropdownToggle(e, 3)}
                            >
                              <h5>First Aid Courses</h5>
                            </a>
                            <div
                              className={`dropdown-menu multiple-columns-container w3_bg ${
                                dropdownOpen === 3 ? "open" : ""
                              }`}
                            >
                              <div className="multiple-columns w3_bg">
                                {firstAidCourses.map((link, index) => {
                                  return (
                                    <div
                                      className="col-items w3_bg"
                                      key={index}
                                    >
                                      <img
                                        src={`/header-icons/pin-icon.svg`}
                                        className="icon"
                                        alt=""
                                      />
                                      <a href={link.link}>{link.title}</a>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="mh menu-course-category desktop-height-36">
                          <div className="col-xs-12 col-sm-12 p--zero dropdown w3_bg">
                            <a
                              href="https://www.skillstg.co.uk/category/iosh-managing-safely-courses/"
                              className="dropdown-toggle "
                            >
                              <h5>IOSH Managing Safely Courses</h5>
                            </a>
                          </div>
                        </li>
                        <li className="mh menu-course-category desktop-height-35">
                          <div className="col-xs-12 col-sm-12 p--zero dropdown w3_bg">
                            <a
                              href="https://www.skillstg.co.uk/category/domestic-gas/"
                              className="dropdown-toggle "
                            >
                              <h5>Domestic Gas Courses</h5>
                            </a>
                          </div>
                        </li>
                        <li className="mh menu-course-category desktop-height-35">
                          <div className="col-xs-12 col-sm-12 p--zero dropdown w3_bg">
                            <a
                              href="https://www.skillstg.co.uk/category/commercial-gas/"
                              className="dropdown-toggle "
                            >
                              <h5>Commercial Gas Courses</h5>
                            </a>
                          </div>
                        </li>
                        <li className="mh menu-course-category desktop-height-35">
                          <div className="col-xs-12 col-sm-12 p--zero dropdown w3_bg">
                            <a
                              href="https://www.skillstg.co.uk/category/lpg/"
                              className="dropdown-toggle "
                            >
                              <h5>LPG Courses</h5>
                            </a>
                          </div>
                        </li>
                        <li className="mh menu-course-category desktop-height-35">
                          <div className="col-xs-12 col-sm-12 p--zero dropdown w3_bg">
                            <a
                              href="https://www.skillstg.co.uk/category/oil/"
                              className="dropdown-toggle "
                            >
                              <h5>Oil Courses</h5>
                            </a>
                          </div>
                        </li>
                        <li className="mh menu-course-category">
                          <div className="col-xs-12 col-sm-12 p--zero dropdown w3_bg">
                            <a
                              href="https://www.skillstg.co.uk/category/renewable-courses/"
                              className="dropdown-toggle "
                            >
                              <h5>Renewable Courses</h5>
                            </a>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children">
                  <a
                    href=""
                    className="header__link"
                    onClick={(e) => toggleMobile(e, "aboutUs")}
                  >
                    <span>About Us</span>
                    <div className="open_sub_menu_arrow">
                      <ArrowDownIcon />
                    </div>
                  </a>
                  <ul
                    className={`sub-menu regular-nav ${
                      headerMobileOpens.aboutUs ? "open" : ""
                    }`}
                  >
                    <li
                      className="mobile-menu-back"
                      onClick={(e) => toggleMobile(e, "aboutUs")}
                    >
                      Back
                    </li>
                    <li>
                      <a href="https://www.skillstg.co.uk/about-us/">
                        Why Choose Us
                      </a>
                    </li>
                    <li>
                      <a href="https://www.skillstg.co.uk/paisley-academy/">
                        Paisley Academy
                      </a>
                    </li>
                    <li>
                      <a href="https://www.skillstg.co.uk/dalgety-bay-academy/">
                        Dalgety Bay Academy
                      </a>
                    </li>
                    <li>
                      <a href="https://www.skillstg.co.uk/in-house-training/">
                        In-House Training
                      </a>
                    </li>
                    <li>
                      <a href="https://www.skillstg.co.uk/blog/">Blog</a>
                    </li>
                    <li>
                      <a href="https://www.skillstg.co.uk/about-us/vacancies/">
                        Job Vacancies
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="menu-item menu-item-type-custom">
                  <a
                    href="https://www.skillstg.co.uk/contact-us/"
                    className="header__link"
                  >
                    <span>Contact Us</span>
                  </a>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children">
                  <a
                    href=""
                    className="header__link"
                    onClick={(e) => toggleMobile(e, "resources")}
                  >
                    <span>Resources</span>
                    <div className="open_sub_menu_arrow">
                      <ArrowDownIcon />
                    </div>
                  </a>
                  <ul
                    className={`sub-menu regular-nav ${
                      headerMobileOpens.resources ? "open" : ""
                    }`}
                  >
                    <li
                      className="mobile-menu-back"
                      onClick={(e) => toggleMobile(e, "resources")}
                    >
                      Back
                    </li>
                    <li>
                      <a href="https://www.skillstg.co.uk/blog/category/career-change-hub/">
                        Career Change
                      </a>
                    </li>
                    <li>
                      <a href="https://www.skillstg.co.uk/blog/category/electrical/">
                        Electrical
                      </a>
                    </li>
                    <li>
                      <a href="https://www.skillstg.co.uk/blog/category/plumbing/">
                        Plumbing Resources
                      </a>
                    </li>
                    <li>
                      <a href="https://www.skillstg.co.uk/blog/category/renewables/">
                        Renewable Energy
                      </a>
                    </li>
                    <li>
                      <a href="https://www.skillstg.co.uk/blog/category/first-aid/">
                        First Aid Advice
                      </a>
                    </li>
                    <li>
                      <a href="https://www.skillstg.co.uk/blog/category/how-to-guides-hub/">
                        How To Guides
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
              <div className="header__mob-item">
                {/* <div className="header__second-logo">
                              <TrustPilotLogo />
                          </div> */}
                <div className="header__contact">
                  <span className="header-contact-title">Freephone</span>
                  <a href="tel:08081642780" className="header-contact-phone">
                    0808 164 2780
                  </a>
                </div>
                <button
                  className="btn btn--info btn--sm-rounded rounded ttu search-form__button"
                  style={{ position: "static", width: "50px", height: "50px" }}
                  onClick={() => {
                    setSearchActive(!searchActive);
                  }}
                >
                  <SearchIcon />
                </button>

                <form
                  className="mobile-menu-search"
                  role="search"
                  method="get"
                  action="https://www.skillstg.co.uk"
                >
                  <input
                    id="search"
                    className="search"
                    type="text"
                    name="s"
                    placeholder="Search for a course, qualification or service…"
                  />
                  <button type="submit" className="search-btn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10.2583 9.66667L10.025 9.44167C10.8417 8.49167 11.3333 7.25833 11.3333 5.91667C11.3333 2.925 8.90833 0.5 5.91667 0.5C2.925 0.5 0.5 2.925 0.5 5.91667C0.5 8.90833 2.925 11.3333 5.91667 11.3333C7.25833 11.3333 8.49167 10.8417 9.44167 10.025L9.66667 10.2583V10.9167L13.2119 14.4548C13.5552 14.7974 14.1112 14.7971 14.4542 14.4542V14.4542C14.7971 14.1112 14.7974 13.5552 14.4548 13.2119L10.9167 9.66667H10.2583ZM2.16667 5.91667C2.16667 3.84167 3.84167 2.16667 5.91667 2.16667C7.99167 2.16667 9.66667 3.84167 9.66667 5.91667C9.66667 7.99167 7.99167 9.66667 5.91667 9.66667C3.84167 9.66667 2.16667 7.99167 2.16667 5.91667Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </form>
              </div>
            </nav>
            <div className="header__right-side">
              <div className="header__second-logo">
                <TrustPilotLogo />
              </div>
              <div className="header__contact">
                <span className="header-contact-title">Freephone</span>
                <a href="tel:08081642780" className="header-contact-phone">
                  0808 164 2780
                </a>
              </div>
              {/*<button className="header__button">*/}
              {/*    <SearchIcon />*/}
              {/*</button>*/}

              <button
                className="btn btn--info btn--sm-rounded rounded ttu search-form__button"
                style={{ position: "static", width: "50px", height: "50px" }}
                onClick={() => {
                  setSearchActive(!searchActive);
                }}
              >
                {/* <SearchIcon /> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.2583 9.66667L10.025 9.44167C10.8417 8.49167 11.3333 7.25833 11.3333 5.91667C11.3333 2.925 8.90833 0.5 5.91667 0.5C2.925 0.5 0.5 2.925 0.5 5.91667C0.5 8.90833 2.925 11.3333 5.91667 11.3333C7.25833 11.3333 8.49167 10.8417 9.44167 10.025L9.66667 10.2583V10.9167L13.2119 14.4548C13.5552 14.7974 14.1112 14.7971 14.4542 14.4542V14.4542C14.7971 14.1112 14.7974 13.5552 14.4548 13.2119L10.9167 9.66667H10.2583ZM2.16667 5.91667C2.16667 3.84167 3.84167 2.16667 5.91667 2.16667C7.99167 2.16667 9.66667 3.84167 9.66667 5.91667C9.66667 7.99167 7.99167 9.66667 5.91667 9.66667C3.84167 9.66667 2.16667 7.99167 2.16667 5.91667Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </div>
          <form
            className={`nav-search-form ${searchActive ? "is-open" : ""}`}
            role="search"
            method="get"
            action="https://www.skillstg.co.uk"
          >
            <input
              id="search"
              className="search"
              type="text"
              name="s"
              placeholder="Search..."
            />
          </form>
        </nav>
      </header>
      {headerHoverBg ? <div className="header_hover_bg"></div> : null}
    </>
  );
});
