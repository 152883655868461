import React from "react";
import { FAQ } from "./FAQ/FAQ";
import { Accreditations } from "../../components/Accreditations";
import { SearchCourse } from "./SearchCourse/SearchCourse";
import { useSelector } from "react-redux";

import bgImage from "../../assets/img/img-09-10-23/body-image-bg-2.svg";
import { Courses } from "./Services/Courses";
import { Guarantees } from "../../components/Guarantees";

export const CoursePage = () => {
  const courses = useSelector((state) => state.courses.courses);
  // console.log("courses:", courses);

  return (
    <div
      className="wrapper header-fixed bg-primary-100 bg-styles-top-center"
      style={{
        backgroundImage: `url(${bgImage})`,
      }}
    >
      <main className="content">
        <section className="section-40 pb-100 pb-md-30">
          <div className="container container-900">
            <div
              className="firs-screen-home--container mb-30 r-4"
              style={{ minHeight: "90px" }}
            >
              <div className="firs-screen-home__content">
                {/* Emergency First Aid at Work */}
                <h2 className="heading-2">
                  {courses?.length
                    ? `${courses[0]?.name} (${
                        courses?.length ? `${courses[0]?.pcode}` : ""
                      })`
                    : ""}
                </h2>
                {/* <p className="heading-3 title-16-mob">6 Hours ({courses?.length ? `${courses[0]?.pcode}` : ''})</p> */}
              </div>
            </div>
            <h3 className="heading-3 title-16-mob">Course Availability</h3>
            <p>
              Please select your location and date first. It will help us
              suggest the best option for you.
            </p>
          </div>
        </section>
        <SearchCourse />
        <section className="section-60 bg-white pt-4 pt-md-0">
          <div className="container container-670">
            <Courses />
            <div className="items items-40">
              <Guarantees />
              <Accreditations />
              <FAQ />
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};
