import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

export const YourLocationModal = ({
  close,
  submit,
  isOpen = false,
  setOpen,
}) => {
  const [modalOpen, setModalOpen] = useState(isOpen);
  const [error, setError] = useState(null);

  const closeAction = () => {
    document.querySelector("body").classList.remove("lock");
    setModalOpen(false);
    setTimeout(() => {
      close && close();
    }, 300);
  };

  useEffect(() => {
    setTimeout(() => {
      document.querySelector("body").classList.add("lock");
      setModalOpen(true);
      if (setOpen) setOpen(true);
    }, 300);
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setFocus,
  } = useForm();

  const submitHandler = async (data) => {
    setError(null);

    const key = Object.keys(data);
    window.localStorage.setItem(key, data[key]);

    if (submit) {
      let res = await submit(data);
      // console.log('res:', res);
      if (res.success === false) {
        setError("Location not found - try a different post code or town/city");
      } else {
        setTimeout(() => {
          closeAction();
        }, 300);
      }
    }
  };

  return (
    <div className={`modal ${modalOpen ? "modal--show" : ""}`}>
      <div
        className="modal__dialog"
        style={{ width: "100%", maxWidth: "calc(582px + 40px)" }}
      >
        <div className="modal__content">
          <div className="modal__body py-40 px-60">
            <div className="d-flex flex-column text-center">
              <h3 className="heading-3 mb-10" style={{ lineHeight: "30px" }}>
                Your Location
              </h3>
              <p className="def-text-1">
                Please add your location to the field below to proceed
              </p>
            </div>

            <form onSubmit={handleSubmit(submitHandler)} className="form pt-30">
              <div className="form-body">
                <div className="row gutters-y-15">
                  <div className="col-12">
                    <div className="form-group">
                      <div className="relative">
                        <input
                          onInput={() => {
                            setError(null);
                          }}
                          className={`input --placeholder-custom ${
                            errors?.user_city || error ? "error" : ""
                          }`}
                          type="text"
                          placeholder="Enter Your Location or Post Code..."
                          {...register("user_city", {
                            required: true,
                            minLength: 3,
                          })}
                        />
                        {error && (
                          <a
                            href="#"
                            className="input-clear-btn"
                            onClick={(e) => {
                              e.preventDefault();
                              setValue("user_city", "");
                              setFocus("user_city");
                              setError(null);
                            }}
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_610_10850)">
                                <path
                                  d="M15.625 4.375L4.375 15.625"
                                  stroke="#EE3D3D"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M15.625 15.625L4.375 4.375"
                                  stroke="#EE3D3D"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_610_10850">
                                  <rect width="20" height="20" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </a>
                        )}
                      </div>
                      {error && (
                        <div className="error-badge">
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_610_10855)">
                              <path
                                d="M16.6498 13.2251L10.5009 2.54677C10.3473 2.28515 10.1279 2.06823 9.86463 1.91751C9.60131 1.76679 9.30318 1.6875 8.99978 1.6875C8.69638 1.6875 8.39824 1.76679 8.13493 1.91751C7.87161 2.06823 7.65226 2.28515 7.49861 2.54677L1.34978 13.2251C1.20194 13.4782 1.12402 13.766 1.12402 14.059C1.12402 14.3521 1.20194 14.6399 1.34978 14.8929C1.50146 15.1561 1.72044 15.3742 1.98425 15.5249C2.24806 15.6755 2.54719 15.7532 2.85095 15.75H15.1486C15.4521 15.753 15.751 15.6751 16.0145 15.5245C16.278 15.3739 16.4968 15.1559 16.6484 14.8929C16.7964 14.64 16.8746 14.3523 16.8748 14.0592C16.8751 13.7662 16.7974 13.4783 16.6498 13.2251ZM8.43728 7.31255C8.43728 7.16336 8.49654 7.02029 8.60203 6.9148C8.70752 6.80931 8.85059 6.75005 8.99978 6.75005C9.14896 6.75005 9.29204 6.80931 9.39753 6.9148C9.50301 7.02029 9.56228 7.16336 9.56228 7.31255V10.125C9.56228 10.2742 9.50301 10.4173 9.39753 10.5228C9.29204 10.6283 9.14896 10.6875 8.99978 10.6875C8.85059 10.6875 8.70752 10.6283 8.60203 10.5228C8.49654 10.4173 8.43728 10.2742 8.43728 10.125V7.31255ZM8.99978 13.5C8.8329 13.5 8.66977 13.4506 8.53102 13.3578C8.39226 13.2651 8.28412 13.1334 8.22025 12.9792C8.15639 12.825 8.13968 12.6554 8.17224 12.4917C8.2048 12.328 8.28516 12.1777 8.40316 12.0597C8.52116 11.9417 8.6715 11.8613 8.83517 11.8288C8.99884 11.7962 9.16849 11.8129 9.32267 11.8768C9.47684 11.9406 9.60862 12.0488 9.70133 12.1875C9.79404 12.3263 9.84353 12.4894 9.84353 12.6563C9.84353 12.8801 9.75463 13.0947 9.5964 13.2529C9.43816 13.4112 9.22355 13.5 8.99978 13.5Z"
                                fill="white"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_610_10855">
                                <rect width="18" height="18" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          <span style={{ flex: 1 }}>{error}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-footer">
                <div className="dbl-btns dbl-btns--mob dbl-btns--16 justify-content-between">
                  <button
                    onClick={closeAction}
                    className="btn btn--outline-info btn--md radius-5 ttu min-w-120"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className={`btn btn--info btn--md radius-5 ttu min-w-120 ${
                      errors?.user_city ? "--disabled" : ""
                    }`}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
