import React, { useEffect } from "react";
import { PersonalDetailsForm } from "../PersonalDetailsForm";

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import oneUserIcon from "../../../assets/img/img-09-10-23/one-user-ico.png";
import multipleUsersIcon from "../../../assets/img/img-09-10-23/multiple-users-ico.png";
import { ReactComponent as ChevronRightIcon } from "../../../assets/img/img-09-10-23/arrow-right.svg";
import { addLearner, setBookingType } from "../../../redux/slices/coursesSlice";

export const PersonalDetails = ({ prevHandler, nextHandler }) => {
  const dispatch = useDispatch();
  const bookingType = useSelector((state) => state.courses.bookingType);
  const learners = useSelector((state) => state.courses.learners);
  const {
    register,
    unregister,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const submitHandler = (data) => {
    dispatch(addLearner({ item: data, index: 0 }));
    nextHandler();
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <h3 className="mb-24">Who are you booking for?</h3>
      <label className="custom-radio mb-12">
        <input
          type="radio"
          name="user-type"
          hidden
          checked={bookingType === "single"}
          onChange={() => dispatch(setBookingType("single"))}
        />
        <div className="custom-radio__body">
          <span className="custom-radio__icon">
            <img src={oneUserIcon} alt="" />
          </span>
          <span className="flex-auto">I’m booking for myself</span>
          <span className="fake-icon"></span>
        </div>
      </label>
      <label className="custom-radio mb-30">
        <input
          type="radio"
          name="user-type"
          hidden
          checked={bookingType === "multiple"}
          onChange={() => {
            dispatch(setBookingType("multiple"));
          }}
        />
        <div className="custom-radio__body">
          <span className="custom-radio__icon">
            <img src={multipleUsersIcon} alt="" />
          </span>
          <span className="flex-auto">
            I’m booking for one or more colleagues
          </span>
          <span className="fake-icon"></span>
        </div>
      </label>
      <h3 className="mb-12">
        {bookingType === "single" ? "Your Details" : "Billing Contact Details"}
      </h3>
      <p className="mb-24 mt-10 color-primary-light lh-24">
        {bookingType === "single"
          ? "Please provide your contact details"
          : "Please provide billing contact details"}
      </p>
      <PersonalDetailsForm
        register={register}
        control={control}
        errors={errors}
        learner={learners.length && learners[0]}
        setValue={setValue}
        index={0}
        unregister={unregister}
      />
      <div className="page-action d-flex align-items-center justify-content-between">
        <button
          onClick={prevHandler}
          className="btn btn--outline-secondary btn--md radius-5 ttu min-w-120"
        >
          Back
        </button>
        <button className="btn btn--success btn--md radius-5 ttu min-w-240">
          Continue
          <span className="btn-icon ml-10">
            <ChevronRightIcon />
          </span>
        </button>
      </div>
    </form>
  );
};
