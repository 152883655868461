import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as ChevronDownIcon } from "../../assets/img/icons/arrow-down-dark.svg";

export const Accordion = ({
  title,
  children,
  type = "primary",
  isOpen,
  headClasses,
  ...rest
}) => {
  const content = useRef();
  const sensitive = useRef();
  const [active, setActive] = useState(isOpen ? "active" : "");
  const [height, setHeight] = useState("0px");

  const toggleAccordion = () => {
    setActive(active === "" ? "active" : "");
    setHeight(
      active === "active" ? "0px" : `${content.current.scrollHeight}px`,
    );
  };

  useEffect(() => {
    if (content?.current && isOpen) {
      setHeight(`${content.current.scrollHeight}px`);
    }
  }, [content]);

  return (
    <>
      {type === "primary" && (
        <div
          className={`fag-item bg-primary-100 r-4 fag-item--sm ${active}`}
          ref={sensitive}
        >
          <div
            className={`fag-item__button ttu ${headClasses ? headClasses : ""}`}
            onClick={toggleAccordion}
          >
            <div className="fw-600 flex-auto">{title}</div>
            <span className="fag-item__icon">
              <ChevronDownIcon />
            </span>
          </div>
          <div
            ref={content}
            style={{ maxHeight: `${height}` }}
            className="fag-item__body"
          >
            <div className="fag-item__answer">
              <p>{children}</p>
            </div>
          </div>
        </div>
      )}
      {type === "secondary" && (
        <div className={`spoller ${active}`} ref={sensitive} {...rest}>
          <div
            className={`spoller__head mb-24 spoller__head-style-blue active ${
              headClasses ? headClasses : ""
            }`}
            onClick={toggleAccordion}
          >
            <div className="fw-700">{title}</div>
            <span className="spoller__line"></span>
            <span className="spoller__head-arrow">
              <ChevronDownIcon />
            </span>
          </div>
          <div
            ref={content}
            style={{ maxHeight: `${height}` }}
            className="spoller__body"
          >
            <div>{children}</div>
          </div>
        </div>
      )}
    </>
  );
};
