import React, { useEffect, useMemo, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";

export const MainLayout = () => {
  const pathname = useLocation();
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const parameters = ["domain", "utm_source", "utm_medium"];

  useEffect(() => {
    let searchQuery = window.location.search;
    parameters.map((paramName) => {
      if (params.get(paramName)) {
        localStorage.setItem(paramName, params.get(paramName));
      }

      let paramValue = localStorage.getItem(paramName);

      if (!params.get(paramName) && paramValue) {
        searchQuery += searchQuery.includes("?") ? "&" : "?";
        searchQuery += paramName + "=" + paramValue;
      }
    });

    if (window.location.search !== searchQuery) {
      navigate({
        search: searchQuery,
      });
    }

    document.documentElement.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);

  console.log("APP IS WORKING with params --- 👍");

  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};
