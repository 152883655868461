import React, { useState } from "react";
import DatePicker from "react-datepicker";

export const DatePickerCustom = ({ onChange, value, minDate }) => {
  const [startDate, setStartDate] = useState(value ? new Date(value) : null);
  let changeStartDate = (date) => {
    setStartDate(date);
    if (onChange) onChange(date);
  };
  return (
    <DatePicker
      className="datapicker px-10"
      selected={startDate}
      onChange={(date) => changeStartDate(date)}
      dateFormat="MMM d, yyyy"
      placeholderText="Select date"
      minDate={minDate ? new Date(minDate) : new Date()}
    />
  );
};
