import React, { useEffect, useState } from "react";
import { PersonalDetailsBox } from "../PersonalDetailsBox";
import { Accordion } from "../../../components/ui/Accordion";

import { ReactComponent as ChevronRightIcon } from "../../../assets/img/img-09-10-23/arrow-right.svg";
import { useSelector } from "react-redux";

export const ReviewPersonalDetails = ({ prevHandler, nextHandler }) => {
  const learners = useSelector((state) => state.courses.learners);
  const billingData = useSelector((state) => state.courses.billingData);
  const selectedCourse = useSelector((state) => state.courses.selectedCourse);
  console.log("selectedCourse:", selectedCourse);

  useEffect(() => {
    console.log(billingData);
  }, [billingData]);

  const submitHandler = (e) => {
    e.preventDefault();
    nextHandler();
  };

  return (
    <form onSubmit={submitHandler}>
      <h3 className="mb-12">Review Your Details</h3>
      <div className="mb-30 color-primary-700 fs-14 lh-22">
        <span className="fw-600">{selectedCourse?.center} </span>
        {/* (6 Hours) */}
        {selectedCourse?.duration ? `(${selectedCourse?.duration})` : null}
        <span className="fw-300">({selectedCourse?.pcode})</span>
      </div>
      <PersonalDetailsBox learner={learners && learners[0]} index={0} />
      <hr className="hr mb-24 mt-24" />
      <div className="review-detail-item mb-20">
        <span className="review-detail-item__name">Billing Street:</span>
        <span className="review-detail-item__deliver"></span>
        <span className="review-detail-item__value">{billingData?.street}</span>
      </div>
      <div className="review-detail-item mb-20">
        <span className="review-detail-item__name">Billing City:</span>
        <span className="review-detail-item__deliver"></span>
        <span className="review-detail-item__value">{billingData?.city}</span>
      </div>
      <div className="review-detail-item mb-20">
        <span className="review-detail-item__name">Billing Country:</span>
        <span className="review-detail-item__deliver"></span>
        <span className="review-detail-item__value">
          {billingData?.country}
        </span>
      </div>
      <div className="review-detail-item mb-20">
        <span className="review-detail-item__name">Billing Postal Code:</span>
        <span className="review-detail-item__deliver"></span>
        <span className="review-detail-item__value">
          {billingData?.postalCode}
        </span>
      </div>
      <div className="review-detail-item mb-20">
        <span className="review-detail-item__name">Billing County</span>
        <span className="review-detail-item__deliver"></span>
        <span className="review-detail-item__value">
          {billingData?.state || billingData?.county}
        </span>
      </div>
      {learners.length > 1 &&
        learners.map((learner, index) => {
          if (index !== 0) {
            return (
              <Accordion
                title={`Learner ${index}`}
                type="secondary"
                key={index}
              >
                <PersonalDetailsBox learner={learner} index={index} />
              </Accordion>
            );
          }
        })}
      <div className="page-action d-flex align-items-center justify-content-between">
        <button
          onClick={prevHandler}
          className="btn btn--outline-secondary btn--md radius-5 ttu min-w-120"
        >
          Back
        </button>
        <button className="btn btn--success btn--md radius-5 ttu min-w-240">
          Continue to payment
          {/* <span className="btn-icon ml-10">
            <ChevronRightIcon />
          </span> */}
        </button>
      </div>
    </form>
  );
};
