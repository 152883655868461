export const formatPrice = (value) => {
  if (String(value).includes(".") && String(value).split(".")[1].length < 2) {
    return (
      String(value).split(".")[0] + "." + String(value).split(".")[1] + "0"
    );
  } else if (!String(value).includes(".")) {
    return String(value) + ".00";
  } else {
    return value;
  }
};

export const creditCardType = (cc) => {
  let localCC = String(cc);
  let localCCLength = localCC.length;
  if (localCCLength < 16) {
    for (let index = 0; index < 16 - localCCLength; index++) {
      localCC += "0";
    }
  }

  let amex = new RegExp("^3[47][0-9]{13}$");
  let visa = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$");
  let cup1 = new RegExp("^62[0-9]{14}[0-9]*$");
  let cup2 = new RegExp("^81[0-9]{14}[0-9]*$");

  let mastercard = new RegExp("^5[1-5][0-9]{14}$");
  let mastercard2 = new RegExp("^2[2-7][0-9]{14}$");

  let disco1 = new RegExp("^6011[0-9]{12}[0-9]*$");
  let disco2 = new RegExp("^62[24568][0-9]{13}[0-9]*$");
  let disco3 = new RegExp("^6[45][0-9]{14}[0-9]*$");

  let diners = new RegExp("^3[0689][0-9]{12}[0-9]*$");
  let jcb = new RegExp("^35[0-9]{14}[0-9]*$");

  if (visa.test(localCC)) {
    return "VISA";
  }
  if (amex.test(localCC)) {
    return "AMEX";
  }
  if (mastercard.test(localCC) || mastercard2.test(localCC)) {
    return "MASTERCARD";
  }
  if (disco1.test(localCC) || disco2.test(localCC) || disco3.test(localCC)) {
    return "DISCOVER";
  }
  if (diners.test(localCC)) {
    return "DINERS";
  }
  if (jcb.test(localCC)) {
    return "JCB";
  }
  if (cup1.test(localCC) || cup2.test(localCC)) {
    return "CHINA_UNION_PAY";
  }

  return null;
};

export const isValidExpirationDate = (expiryDate) => {
  const currentYear = new Date().getFullYear() % 100;
  const currentMonth = new Date().getMonth() + 1;

  if (expiryDate === "") {
    return true;
  } else {
    const enteredMonth = Number(expiryDate.slice(0, 2));
    const enteredYear = Number(expiryDate.slice(2, 4));

    if (enteredMonth > 12) {
      return false;
    }
    if (currentYear < enteredYear) {
      return true;
    }
    if (currentYear === enteredYear && currentMonth < enteredMonth) {
      return true;
    }
    return false;
  }
};

export const formatDate = (date) => {
  if (!date?.getMonth) {
    return "unknown";
  }
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const month = months[date?.getMonth()];
  const day = date?.getDate();
  const year = date?.getFullYear();

  const formattedDate = `${month} ${day}, ${year}`;

  return formattedDate;
};

export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const formatDate = `${date.getDate()} ${date
    .toLocaleString("en-US", { month: "short" })
    .toLocaleUpperCase()} ${date.getFullYear()}`;
  return formatDate;
};

export const formatTimestampNew = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const formatDate = `${date.toLocaleString("en-US", { weekday: 'long' })} ${date.getDate()} ${date
    .toLocaleString("en-US", { month: "short" })
    .toLocaleUpperCase()} ${date.getFullYear()}`;
  return formatDate;
};
