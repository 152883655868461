import React, { useState, useEffect } from "react";

import { Controller, setValue, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

export const BookCourseModal = ({ close }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [submited, setSubmited] = useState(false);
  const courses = useSelector((state) => state.courses.courses);

  const [data, setData] = useState({
    first_name: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const {
    register,
    unregister,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const closeAction = () => {
    document.querySelector("body").classList.remove("lock");
    setModalOpen(false);
    setTimeout(() => {
      close && close();
    }, 300);
  };

  const submitHandler = (data) => {
    console.log("booked data", data);
    document.querySelector(".onsite-book-form").submit();
    // setSubmited(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setModalOpen(true);
    }, 300);
  }, []);

  return (
    <div className={`modal ${modalOpen ? "modal--show" : ""}`}>
      {!submited ? (
        <div className="modal__dialog modal__dialog--439">
          <div className="modal__content">
            <div className="modal__header">
              <div className="items items-5">
                <h5 className="heading-5 color-white">
                  {courses?.length
                    ? `${courses[0]?.name} (${
                        courses?.length ? `${courses[0]?.pcode}` : ""
                      })`
                    : ""}
                </h5>
                {/* <p className="def-text-1 color-white">6 Hours (EFW)</p> */}
              </div>

              <a
                href="#"
                className="btn btn-close"
                data-close-modal=""
                onClick={(e) => {
                  e.preventDefault();
                  closeAction();
                }}
              >
                <span className="ico ico-15">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.32713 13.8318C0.983892 13.8517 0.646328 13.7376 0.385622 13.5135C-0.128541 12.9963 -0.128541 12.1609 0.385622 11.6437L11.6441 0.385178C12.1789 -0.115229 13.018 -0.0874119 13.5184 0.447363C13.9709 0.930957 13.9973 1.67425 13.5802 2.18868L2.25537 13.5135C1.99803 13.7344 1.66588 13.8483 1.32713 13.8318Z"
                      fill="white"
                    />
                    <path
                      d="M12.5721 13.8318C12.2242 13.8303 11.8908 13.6922 11.6438 13.4472L0.385271 2.18866C-0.0910742 1.6324 -0.0263126 0.795263 0.529948 0.318874C1.02642 -0.106291 1.75863 -0.106291 2.25506 0.318874L13.5799 11.5774C14.1145 12.0779 14.1422 12.9171 13.6416 13.4518C13.6217 13.473 13.6011 13.4936 13.5799 13.5135C13.3026 13.7547 12.9376 13.8699 12.5721 13.8318Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </a>
            </div>
            <div className="modal__body">
              <h4 className="heading-4 mb-15">Book the onsite course</h4>
              <form
                onSubmit={handleSubmit(submitHandler)}
                action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
                className="form onsite-book-form"
              >
                <input
                  type="hidden"
                  name="retURL"
                  value="https://www.skillstg.co.uk/thank-you/"
                />
                <input
                  type="hidden"
                  name="lead_source"
                  value="Course - First Aid at Work Course"
                />
                <input
                  type="hidden"
                  name="Blog_Category__c"
                  value="Course - First Aid at Work Course"
                />
                <input type="hidden" name="Callback__c" value="1" />

                <div className="form-body">
                  <div className="row gutters-y-15">
                    <div className="col-12">
                      <div className="form-group">
                        <label className="form-group-title">
                          First name{" "}
                          <span className="color-danger ms-1">*</span>
                        </label>
                        <input
                          className={`input --placeholder-custom ${
                            errors?.first_name ? "error" : ""
                          }`}
                          type="text"
                          placeholder="Type your First Name"
                          {...register(`first_name`, {
                            required: true,
                            validate: (value) => {
                              return !!value.trim();
                            },
                          })}
                          defaultValue={data?.first_name && data.first_name}
                        />
                        {errors.first_name && (
                          <span className="color-danger">
                            {errors.first_name.message ||
                              "Enter First name please"}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label className="form-group-title">
                          Last Name <span className="color-danger ms-1">*</span>
                        </label>
                        <input
                          className={`input --placeholder-custom ${
                            errors?.lastName ? "error" : ""
                          }`}
                          type="text"
                          placeholder="Type your Last Name"
                          required=""
                          {...register(`lastName`, {
                            required: true,
                            validate: (value) => {
                              return !!value.trim();
                            },
                          })}
                          defaultValue={data?.lastName && data.lastName}
                        />
                        {errors.lastName && (
                          <span className="color-danger">
                            {errors.lastName.message ||
                              "Enter Last Name please"}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label className="form-group-title">
                          Phone Number{" "}
                          <span className="color-danger ms-1">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Type your Phone Number"
                          className={`input --placeholder-custom ${
                            errors?.phone ? "error" : ""
                          }`}
                          {...register(`phone`, {
                            required: true,
                            pattern: {
                              value:
                                /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                              message: "Enter valid phone number please",
                            },
                          })}
                          defaultValue={data?.phone && data.phone}
                        />
                        {errors.phone && (
                          <span className="color-danger">
                            {errors.phone.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label className="form-group-title">
                          Email <span className="color-danger ms-1">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Type your Email"
                          className={`input --placeholder-custom ${
                            errors?.email ? "error" : ""
                          }`}
                          {...register(`email`, {
                            required: true,
                            pattern: {
                              value: /\S+@\S+\.\S+/,
                              message: "Enter valid email please",
                            },
                          })}
                          defaultValue={data?.email && data.email}
                        />
                        {errors.email && (
                          <span className="color-danger">
                            {errors.email.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <input type="hidden" name="00N1r000009BJoC" value=""></input>
                <input type="hidden" name="00N1r000009BJoH" value=""></input>
                <input type="hidden" name="00N1r000009BJoM" value=""></input>
                <input type="hidden" name="00N1r000009BJoR" value=""></input>
                <input type="hidden" name="00N1r000009BJoW" value=""></input>
                <input
                  type="hidden"
                  name="00N1r000009BJob"
                  value="http://localhost:3000/"
                ></input>
                <input
                  type="hidden"
                  name="00N1r000009BJog"
                  value="direct"
                ></input>
                <input
                  type="hidden"
                  name="00N1r000009BAgT"
                  value="https://www.skillstg.co.uk/?_gl=1%2A1z830z%2A_ga%2AMTM4NzQyMTYwOC4xNjk2ODYyMDA2%2A_ga_WN54VK015R%2AMTY5NzYxMDA5NC4xLjEuMTY5NzYxMTQyNC42MC4wLjA."
                ></input>
                <input type="hidden" name="00N1r000009BJns" value="1"></input>

                <div className="form-footer" style={{ marginTop: "24px" }}>
                  <button
                    type="submit"
                    className="btn btn-success
                      btn--md radius-5 ttu w-full"
                  >
                    Book
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div class="modal__dialog modal__dialog--439">
          <div class="modal__content">
            <div class="modal__header">
              <div class="items items-5">
                <h5 class="heading-5 color-white">
                  Emergency First Aid at Work
                </h5>
                <p class="def-text-1 color-white">(EFW)</p>
              </div>

              <a
                href="#"
                className="btn btn-close"
                data-close-modal=""
                onClick={(e) => {
                  e.preventDefault();
                  closeAction();
                }}
              >
                <span className="ico ico-15">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.32713 13.8318C0.983892 13.8517 0.646328 13.7376 0.385622 13.5135C-0.128541 12.9963 -0.128541 12.1609 0.385622 11.6437L11.6441 0.385178C12.1789 -0.115229 13.018 -0.0874119 13.5184 0.447363C13.9709 0.930957 13.9973 1.67425 13.5802 2.18868L2.25537 13.5135C1.99803 13.7344 1.66588 13.8483 1.32713 13.8318Z"
                      fill="white"
                    />
                    <path
                      d="M12.5721 13.8318C12.2242 13.8303 11.8908 13.6922 11.6438 13.4472L0.385271 2.18866C-0.0910742 1.6324 -0.0263126 0.795263 0.529948 0.318874C1.02642 -0.106291 1.75863 -0.106291 2.25506 0.318874L13.5799 11.5774C14.1145 12.0779 14.1422 12.9171 13.6416 13.4518C13.6217 13.473 13.6011 13.4936 13.5799 13.5135C13.3026 13.7547 12.9376 13.8699 12.5721 13.8318Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </a>
            </div>
            <div class="modal__body py-25">
              <div class="form">
                <div
                  class="form-body text-center 
                  align-items-center max-w-275 mx-auto items items-10"
                >
                  <span class="ico ico-50">
                    <svg
                      width="51"
                      height="50"
                      viewBox="0 0 51 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M25.5 39.1703H8.64062C6.65 39.1703 5.03125 37.5516 5.03125 35.5609V12.5312C5.03125 10.5406 6.65 8.92188 8.64062 8.92188H12.0344V9.77188C12.0344 10.5484 12.6641 11.1781 13.4406 11.1781C14.2172 11.1781 14.8469 10.5484 14.8469 9.77188V8.92188H31.6656V9.77188C31.6656 10.5484 32.2953 11.1781 33.0719 11.1781C33.8484 11.1781 34.4781 10.5484 34.4781 9.77188V8.92188H37.8594C39.8484 8.92188 41.4688 10.5406 41.4688 12.5312V22.5781C41.4688 23.3547 42.0984 23.9844 42.875 23.9844C43.6516 23.9844 44.2812 23.3547 44.2812 22.5781V12.5312C44.2812 8.99063 41.4 6.10938 37.8594 6.10938H34.4781V5.39063C34.4781 4.61406 33.8484 3.98438 33.0719 3.98438C32.2953 3.98438 31.6656 4.61406 31.6656 5.39063V6.10938H14.8469V5.39063C14.8469 4.61406 14.2172 3.98438 13.4406 3.98438C12.6641 3.98438 12.0344 4.61406 12.0344 5.39063V6.10938H8.64062C5.1 6.10938 2.21875 8.99063 2.21875 12.5312V35.5625C2.21875 39.1047 5.1 41.9844 8.64062 41.9844H25.5C26.2766 41.9844 26.9062 41.3547 26.9062 40.5781C26.9062 39.8016 26.2766 39.1703 25.5 39.1703Z"
                        fill="#09983F"
                      />
                      <path
                        d="M13.1469 19.1313C12.25 19.1313 11.5234 19.8579 11.5234 20.7548C11.5234 21.6517 12.25 22.3782 13.1469 22.3782C14.0438 22.3782 14.7703 21.6517 14.7703 20.7548C14.7703 19.8579 14.0438 19.1313 13.1469 19.1313Z"
                        fill="#09983F"
                      />
                      <path
                        d="M23.2543 19.1313C22.3574 19.1313 21.6309 19.8579 21.6309 20.7548C21.6309 21.6517 22.3574 22.3782 23.2543 22.3782C24.1512 22.3782 24.8777 21.6517 24.8777 20.7548C24.8777 19.8579 24.1496 19.1313 23.2543 19.1313Z"
                        fill="#09983F"
                      />
                      <path
                        d="M31.7383 20.7548C31.7383 21.6517 32.4648 22.3782 33.3617 22.3782C34.2586 22.3782 34.9852 21.6517 34.9852 20.7548C34.9852 19.8579 34.2586 19.1313 33.3617 19.1313C32.4648 19.1313 31.7383 19.8579 31.7383 20.7548Z"
                        fill="#09983F"
                      />
                      <path
                        d="M13.1469 27.9766C12.25 27.9766 11.5234 28.7031 11.5234 29.6C11.5234 30.4969 12.25 31.2234 13.1469 31.2234C14.0438 31.2234 14.7703 30.4969 14.7703 29.6C14.7703 28.7031 14.0438 27.9766 13.1469 27.9766Z"
                        fill="#09983F"
                      />
                      <path
                        d="M23.2543 27.9766C22.3574 27.9766 21.6309 28.7031 21.6309 29.6C21.6309 30.4969 22.3574 31.2234 23.2543 31.2234C24.1512 31.2234 24.8777 30.4969 24.8777 29.6C24.8777 28.7031 24.1496 27.9766 23.2543 27.9766Z"
                        fill="#09983F"
                      />
                      <path
                        d="M38.7914 26.0391C33.2836 26.0391 28.8008 30.5203 28.8008 36.0281C28.8008 41.5344 33.282 46.0172 38.7914 46.0172C44.3008 46.0172 48.782 41.5359 48.782 36.0281C48.7805 30.5187 44.2992 26.0391 38.7914 26.0391ZM38.7914 43.2016C34.8352 43.2016 31.6148 39.9828 31.6148 36.0266C31.6148 32.0703 34.8352 28.85 38.7914 28.85C42.7477 28.85 45.968 32.0703 45.968 36.0266C45.968 39.9828 42.7477 43.2016 38.7914 43.2016Z"
                        fill="#09983F"
                      />
                      <path
                        d="M40.83 33.4705L37.7488 35.9533L36.9816 34.9736C36.5004 34.3627 35.6207 34.2565 35.0066 34.7361C34.3957 35.2158 34.2894 36.0986 34.7691 36.7111L36.416 38.8111C36.6472 39.1065 36.9879 39.2986 37.3613 39.3408C37.4144 39.3471 37.4675 39.3502 37.5222 39.3502C37.841 39.3502 38.1535 39.2408 38.4035 39.0393L42.5941 35.6643C43.2004 35.1752 43.2941 34.2908 42.8082 33.6861C42.3207 33.0815 41.4347 32.9815 40.83 33.4705Z"
                        fill="#09983F"
                      />
                    </svg>
                  </span>
                  <h5 class="heading-5">
                    Thank you for sending us your details. A member of our team
                    will be in touch shortly.
                  </h5>
                </div>
                <div class="form-footer">
                  <a
                    href="#"
                    data-close-modal=""
                    class="btn btn-success
                      btn--md radius-5 ttu w-full"
                    onClick={(e) => {
                      e.preventDefault();
                      closeAction();
                    }}
                  >
                    OK
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
