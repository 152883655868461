import React, { useEffect, useState } from "react";
import { SelectCustom } from "../../../components/ui/SelectCustom";

import { ReactComponent as ChevronRightIcon } from "../../../assets/img/img-09-10-23/arrow-right.svg";
import { useDispatch, useSelector } from "react-redux";

import { statesOptions } from "../../../data/constants";
import { Controller, useForm } from "react-hook-form";
import { setBillingData } from "../../../redux/slices/coursesSlice";

export const BillingDetails = ({ prevHandler, nextHandler }) => {
  const billingData = useSelector((state) => state.courses.billingData);
  const [country, setCountry] = useState("");
  const dispatch = useDispatch();
  const bookingType = useSelector((state) => state.courses.bookingType);

  const countryOptions = [
    { label: "United Kingdom", value: "GB" },
    { label: "Ireland", value: "IE" },
  ];

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    resetField,
  } = useForm({
    defaultValues: {
      country: billingData?.country || countryOptions[0].value,
      county: billingData?.city || statesOptions[country || "GB"][0],
    },
  });

  const submitHandler = (data) => {
    dispatch(setBillingData(data));
    nextHandler();
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <h3 className="mb-24">
        {bookingType !== "single"
          ? "Billing Address Details"
          : "Billing Details"}
      </h3>
      <div className="row gutters-20 mb-30">
        <div className="col-12">
          <div className="form-group">
            <label className="form-group-title">
              Billing Country<span className="color-danger ms-1">*</span>
            </label>
            <Controller
              name="country"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, ref } }) => (
                <SelectCustom
                  options={countryOptions}
                  onBlur={onBlur}
                  onSelect={(selectedOption) => {
                    onChange(selectedOption);
                    setCountry(selectedOption);
                    resetField("county");
                  }}
                  ref={ref}
                />
              )}
            />
          </div>
        </div>
        <div className="col-12">
          <div className="form-group">
            <label className="form-group-title">
              Billing County<span className="color-danger ms-1">*</span>
            </label>
            <Controller
              name="county"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, ref } }) => (
                <SelectCustom
                  options={statesOptions[country || "GB"].map((itemOption) => {
                    return { value: itemOption, label: itemOption };
                  })}
                  onBlur={onBlur}
                  onSelect={(selectedOption) => {
                    onChange(selectedOption);
                  }}
                  ref={ref}
                />
              )}
            />
          </div>
        </div>
        <div className="col-12">
          <div className="form-group">
            <label className="form-group-title">
              Billing City<span className="color-danger ms-1">*</span>
            </label>
            <input
              type="text"
              className={`input ${errors.city ? "error" : ""}`}
              {...register("city", {
                required: true,
                validate: (value) => {
                  return !!value.trim();
                },
              })}
              placeholder="London"
              defaultValue={billingData?.city}
            />
            {errors.city && (
              <span className="color-danger">
                {errors.city.message || "Enter Billing City please"}
              </span>
            )}
          </div>
        </div>
        <div className="col-12">
          <div className="form-group">
            <label className="form-group-title">
              Billing Street<span className="color-danger ms-1">*</span>
            </label>
            <input
              type="text"
              className={`input ${errors.street ? "error" : ""}`}
              {...register("street", {
                required: true,
                validate: (value) => {
                  return !!value.trim();
                },
              })}
              placeholder="31 North Street "
              defaultValue={billingData?.street}
            />
            {errors.street && (
              <span className="color-danger">
                {errors.street.message || "Enter Billing Street please"}
              </span>
            )}
          </div>
        </div>
        <div className="col-12">
          <div className="form-group">
            <label className="form-group-title">
              Billing Postal Code<span className="color-danger ms-1">*</span>
            </label>
            <input
              type="text"
              className={`input ${errors.postalCode ? "error" : ""}`}
              {...register("postalCode", {
                required: true,
                validate: (value) => {
                  return !!value.trim();
                },
              })}
              placeholder="SE93 2GP"
              defaultValue={billingData?.postalCode}
            />
            {errors.postalCode && (
              <span className="color-danger">
                {errors.postalCode.message ||
                  "Enter Billing Postal Code please"}
              </span>
            )}
          </div>
        </div>
        <div className="col-12">
          <div className="form-group">
            <label className="form-group-title">Company Name</label>
            <input
              type="text"
              className={`input ${errors.companyName ? "error" : ""}`}
              {...register("companyName")}
              defaultValue={billingData?.companyName}
            />
          </div>
        </div>
        <div className="col-12">
          <div className="form-group d-flex flex-column">
            <label className="form-group-title">
              Please state any special requirements or comments here:
            </label>
            <textarea
              className={`textarea resize-none ${
                errors.comments ? "error" : ""
              }`}
              {...register("comments")}
              rows="5"
              defaultValue={billingData?.comments}
            />
          </div>
        </div>
      </div>
      <div className="page-action d-flex align-items-center justify-content-between">
        <button
          onClick={prevHandler}
          className="btn btn--outline-secondary btn--md radius-5 ttu min-w-120"
        >
          Back
        </button>
        <button
          className="btn btn--success btn--md radius-5 ttu min-w-240"
          type="submit"
        >
          Continue
          <span className="btn-icon ml-10">
            <ChevronRightIcon />
          </span>
        </button>
      </div>
    </form>
  );
};
