import React, { forwardRef, memo, useEffect, useState } from "react";
import Select from "react-select";

export const SelectCustom = forwardRef(
  ({ options, containerWidth, onSelect, value, rest }, ref) => {
    const [selectValue, setSelectValue] = useState(options[0]);
    useEffect(() => {
      if (!selectValue) {
        let option = options.find((item) => item.selected) || options[0];
        setSelectValue(option);
      }
    }, []);

    useEffect(() => {
      if (value) {
        setSelectValue(options.find((option) => option.value === value));
      }
    }, [value]);

    const onChangeHandler = (option) => {
      setSelectValue(option);
      onSelect && onSelect(option.value);
    };

    return (
      <Select
        ref={ref}
        styles={{
          dropdownIndicator: (baseStyles) => ({
            ...baseStyles,
            color: "#0D3B5B",
          }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? "#57C3D8" : "#DBE8F4",
            borderRadius: "0px",
            height: "44px",
            fontFamily: "Museo Sans",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "26px",
            color: "#0D3B5B",
            cursor: "pointer",
            "&:hover": {
              borderColor: "#57C3D8",
            },
            "&:active": {
              borderColor: "#57C3D8",
            },
          }),
          option: (baseStyles) => ({
            fontFamily: "Museo Sans",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "26px",
            color: "#49718C",
            padding: "10px",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#f2f2f2",
              transition: "0.5s all",
            },
          }),
          singleValue: (baseStyles) => ({
            ...baseStyles,
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "26px",
            color: "#0D3B5B",
          }),
          container: (baseStyles) => ({
            ...baseStyles,
            width: containerWidth || "100%",
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            boxShadow: "0px 8px 24px rgb(0 0 0 / 15%)",
            borderRadius: "4px",
            marginTop: "8px",
          }),
        }}
        components={{
          IndicatorSeparator: () => null,
        }}
        options={options}
        value={selectValue}
        onChange={onChangeHandler}
        {...rest}
      />
    );
  },
);
