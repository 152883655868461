import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../data/constants";

export const getURLTags = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const tags = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_content",
    "utm_term",
  ];
  const taggedParams = {};
  tags.forEach((tag) => {
    taggedParams[tag] = urlParams.get(tag) || "";
  });
  return taggedParams;
};

export const fetchCourses = createAsyncThunk(
  "coures/fetchCourses",
  async (data, { rejectWithValue }) => {
    try {
      let fetchUrl = `${apiUrl}schedule?pcode=${data.p_code}`;

      if (data?.user_cord) {
        fetchUrl += data.user_cord;
      }

      if (data?.not_found) {
        return null;
      }
      // const result = JSON.parse(resStr)
      const result = await fetch(fetchUrl, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
      }).then(async (r) => await r.json());
      console.log("result:", result);

      let havePartnersCourses = false;
      let partnersResult = [];

      if (havePartnersCourses) {
        // const result = JSON.parse(resStr)
        partnersResult = await fetch(
          `https://partnerskills.webstaginghub.com/api/courses?pcode=${data.p_code}${data.user_cord}`,
          {
            method: "GET",
          },
        ).then(async (r) => await r.json());
        console.log("partnersResult:", partnersResult);
        partnersResult.grouped = partnersResult.grouped.map((item) => ({
          ...item,
          center: {
            partner: true,
            ...item.center,
          },
          schedules: item.schedules
            .map((itemSchedule) => ({
              ...itemSchedule,
              price_online: result.grouped[0].schedules[0].price_online,
            }))
            .sort((a, b) => a.start_date - b.start_date),
        }));
      }

      function compare(a, b) {
        if (a.center.distance < b.center.distance) {
          return -1;
        }
        if (a.center.distance > b.center.distance) {
          return 1;
        }
        return 0;
      }

      return {
        ...result,
        grouped: havePartnersCourses
          ? [...result.grouped, ...partnersResult.grouped].sort(compare)
          : result.grouped,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const postBooking = createAsyncThunk(
  "coures/postBooking",
  async (data, { rejectWithValue }) => {
    try {
      console.log(data);
      let course_id = data.course_id;
      let totalPrice = data.totalPrice;
      let internal_id = data.internal_id;
      let bookingDataSettings = data.bookingType;
      let access_token = data.access_token;
      let instance_url = data.instance_url;
      let bookingDataTransform = data.bookingDataTransform;
      const response = await fetch(
        `${instance_url}/services/apexrest/bookings`,
        {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${access_token}`,
            "Cache-Control": "no-cache",
          },
          body: data.body,
        },
      );
      return await response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
