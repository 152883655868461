import React from "react";
import accreditation1 from "../assets/img/img-09-10-23/logo-1.svg";
import accreditation2 from "../assets/img/img-09-10-23/logo-2.svg";
import accreditation3 from "../assets/img/img-09-10-23/logo-3.svg";
import accreditation4 from "../assets/img/img-09-10-23/logo-4.svg";
import accreditation5 from "../assets/img/img-09-10-23/logo-5.svg";
import accreditation6 from "../assets/img/img-09-10-23/logo-6.svg";
import accreditation7 from "../assets/img/img-09-10-23/logo-7.svg";
import accreditation8 from "../assets/img/img-09-10-23/logo-8.svg";

export const Accreditations = () => {
  return (
    <div className="card card-24 lightcard r-4 card-mob-container">
      <div className="card-header">
        <h4 className="heading-4">Our accreditations</h4>
      </div>
      <div className="card-body p-0">
        <div className="dbl-btns dbl-btns--16 justify-content-between">
          <div className="btn max-h-40">
            <img src={accreditation1} />
          </div>
          <div className="btn max-h-40">
            <img src={accreditation2} />
          </div>
          <div className="btn max-h-40">
            <img src={accreditation3} />
          </div>
          <div className="btn max-h-40">
            <img src={accreditation4} />
          </div>
          <div className="btn max-h-40">
            <img src={accreditation5} />
          </div>
          <div className="btn max-h-40">
            <img src={accreditation6} />
          </div>
          <div className="btn max-h-40">
            <img src={accreditation7} />
          </div>
          <div className="btn max-h-40">
            <img src={accreditation8} />
          </div>
        </div>
      </div>
    </div>
  );
};
