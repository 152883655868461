import React, { useEffect, useState } from "react";
import { Accordion } from "../../../components/ui/Accordion";
import { PersonalDetailsForm } from "../PersonalDetailsForm";

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as AddIcon } from "../../../assets/img/icons/plus.svg";
import { ReactComponent as RemoveIcon } from "../../../assets/img/icons/times.svg";
import { ReactComponent as ChevronRightIcon } from "../../../assets/img/img-09-10-23/arrow-right.svg";
import {
  addLearner,
  removeLearner,
  setLearners,
} from "../../../redux/slices/coursesSlice";

export const AddLearner = ({ prevHandler, nextHandler }) => {
  const initialData = {
    title: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    phone: "",
  };
  const learners = useSelector((state) => state.courses.learners);
  const selectedCourse = useSelector((state) => state.courses.selectedCourse);
  const dispatch = useDispatch();
  const {
    register,
    unregister,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  console.log("errors:", errors);

  const removeLearnerHandler = (index) => {
    dispatch(removeLearner(index));
  };
  const addLearnerHandler = (index) => {
    if (+selectedCourse.space + 1 !== learners.length) {
      dispatch(addLearner({ item: initialData, index: learners.length + 1 }));
    }
  };

  useEffect(() => {
    if (learners.length === 1) {
      dispatch(addLearner({ item: initialData, index: learners.length + 1 }));
    }
  }, [learners]);

  const submitHandler = (data) => {
    console.log(data);
    const updatedLearners = [...learners];
    const keys = Object.keys(data);

    keys.forEach((key) => {
      const [field, index] = key.split("-");
      const learnerIndex = parseInt(index, 10);

      if (
        !isNaN(learnerIndex) &&
        learnerIndex >= 0 &&
        learnerIndex < updatedLearners.length
      ) {
        const updatedLearner = {
          ...updatedLearners[learnerIndex],
          [field]: data[key],
        };

        updatedLearners[learnerIndex] = updatedLearner;
      }
    });

    dispatch(setLearners(updatedLearners));
    nextHandler();
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <h3 className="mb-24">Add Learner</h3>
      {learners.map((learner, index) => {
        if (index !== 0) {
          return (
            <Accordion
              className="mb-30"
              title={`Learner ${index}`}
              type="secondary"
              key={index}
              isOpen={true}
            >
              <PersonalDetailsForm
                register={register}
                control={control}
                errors={errors}
                index={index}
                learner={learners.length >= index && learners[index]}
                unregister={unregister}
              />
              <div className="btn-actions mt-24">
                {learners.length > 2 && (
                  <button
                    className="btn ttu justify-content-sm-start p-2 p-sm-0"
                    onClick={() => removeLearnerHandler(index)}
                  >
                    <span className="d-flex mr-8">
                      <RemoveIcon />
                    </span>
                    <span className="fs-14 color-primary-700 lh-20">
                      Remove Learner
                    </span>
                  </button>
                )}
                {index === learners.length - 1 &&
                  +selectedCourse.space + 1 !== learners.length && (
                    <button
                      className="btn btn--primary-light btn--md radius-5 ttu"
                      onClick={addLearnerHandler}
                    >
                      <span className="d-flex mr-8">
                        <AddIcon />
                      </span>
                      <span className="fs-14 color-primary-700 ">
                        Add Learner
                      </span>
                    </button>
                  )}
              </div>
            </Accordion>
          );
        }
      })}
      <hr className="hr mb-24 mt-24" />
      <div className="page-action d-flex align-items-center justify-content-between">
        <button
          onClick={prevHandler}
          className="btn btn--outline-secondary btn--md radius-5 ttu min-w-120"
        >
          Back
        </button>
        <button className="btn btn--success btn--md radius-5 ttu min-w-240">
          Continue
          <span className="btn-icon ml-10">
            <ChevronRightIcon />
          </span>
        </button>
      </div>
    </form>
  );
};
